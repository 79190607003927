import SaveButton from "@main-components/buttons/save-button";
import SimpleForm from "@main-components/forms/simple-form";
import TextInput from "@main-components/inputs/text-input";
import * as React from "react";
import {
  required,
  Toolbar,
  useAuthenticated,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Card } from "react-bootstrap";

const ShowProfile = (props) => {
  useAuthenticated();

  const { loaded, loading, identity } = useGetIdentity();
  const translate = useTranslate();

  if (!loaded || !identity) return null;

  return <ShowProfileContent id={identity.id} />;
};

function ShowProfileContent({ id }) {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const editControllerProps = useEditController({
    id: id,
    resource: "accounts",
    basePath: "/accounts",
    undoable: false,
    onSuccess: () => {
      notify("resources.accounts.messages.updated_successfully");
      refresh();
    },
  });

  if (editControllerProps.record && editControllerProps.record.id) {
    delete editControllerProps.record.id;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1>{translate("general.profile", { smart_count: 2 })}</h1>
      </div>

      <div className="row">
        <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
          <Card>
            <Card.Header>
              <i className="far fa-user mr-2"></i>{" "}
              {translate("profile.titles.account_info")}
            </Card.Header>
            <Card.Body>
              <SimpleForm
                {...editControllerProps}
                undoable={false}
                toolbar={
                  <Toolbar>
                    <SaveButton label="general.save_changes" />
                  </Toolbar>
                }
              >
                <TextInput
                  source="firstName"
                  label="resources.accounts.fields.firstName"
                  validate={required()}
                />

                <TextInput
                  source="lastName"
                  label="resources.accounts.fields.lastName"
                  validate={required()}
                />

                <TextInput
                  source="email"
                  label="resources.accounts.fields.email"
                  validate={required()}
                  helperText="profile.messages.email_text"
                />
              </SimpleForm>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ShowProfile;
