import * as React from "react";
import {cloneElement} from "react";
import {Form, InputGroup} from "react-bootstrap";

const BaseInput = (props) => {
    const {
        id,
        size,
        InputProps,
        className,
        error,
        touched,
        input,
        type,
        inputRef,
        inputProps,
        ...rest
    } = props;

    const hasError = !!(touched && error);
    const isValid = !!(touched && !error);

    return (
        <InputGroup size={size}>
            {InputProps && InputProps.startAdornment && (
                <InputGroup.Prepend>
                    {cloneElement(InputProps.startAdornment, {
                        onChange: input.onChange,
                        value: input.value,
                    })}
                </InputGroup.Prepend>
            )}
            <Form.Control
                id={id}
                {...input}
                size={size}
                isInvalid={hasError}
                //  isValid={isValid}
                className={className}
                type={type}
                ref={inputRef}
                {...inputProps ?? {}}
                {...rest}
            />
            {InputProps && InputProps.endAdornment && (
                <InputGroup.Append>
                    {" "}
                    {cloneElement(InputProps.endAdornment, {
                        onChange: input.onChange,
                        value: input.value,
                    })}
                </InputGroup.Append>
            )}
        </InputGroup>
    );
};

export default BaseInput;
