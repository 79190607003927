import React from "react";
import DownloadCategoryList from "./list";

const resource = {
  list: DownloadCategoryList,
  /* create: DownloadTypeCreate,
  edit: DownloadTypeEdit, */
  icon: () => <i className="far fa-cloud-download fa-fw"></i>,
};

export default resource;
