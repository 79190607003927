import React from "react";
import AdminCreate from "./create";
import AdminEdit from "./edit";
import AdminList from "./list";
import AdminShow from "./show";

const resource = {
  list: AdminList,
  create: AdminCreate,
  edit: AdminEdit,
  show: AdminShow,
  icon: () => <i className="far fa-users-cog fa-fw"></i>,
};

export default resource;
