import ViewListIcon from "@material-ui/icons/ViewList";
import NotificationList from "./list";
import NotificationShow from "./show";

const resource = {
  list: NotificationList,
  show: NotificationShow,
  icon: ViewListIcon,
};

export default resource;
