import Button from "@main-components/buttons";
import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ArrayInput from "@main-components/inputs/array-input";
import AutocompleteInput from "@main-components/inputs/autocomplete-input";
import NumberInput from "@main-components/inputs/number-input";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import DealerReferenceInput from "@main/dealers/shared/dealer-reference-input";
import {makeStyles, withStyles} from "@material-ui/core";
import * as React from "react";
import {minValue, required, useRedirect, useTranslate} from "react-admin";
import {FormGroup, Modal} from "react-bootstrap";
import OrderAdditionalItemsIterator from "../shared/order-additional-items-iterator.tsx";
import {ProductListInput} from "@main/orders/shared/product-list-input";
import DateInput from "@main-components/inputs/date-input";
import DateTimeUtils from "@misc/utils/date-time-utils";

export const ProductAutoCompleteInput = withStyles({
    suggestionsContainer: {maxWidth: 440},
})(AutocompleteInput);

export const transformOrderData = (data) => {
    data.attachments = data.attachments
        ? data.attachments.map((file) => {
            if (file.url.rawFile) {
                file.url = file.url.rawFile;
            }

            return file;
        })
        : [];

    data.productsFiles = data.productsFiles
        ? data.productsFiles.map((file) => {
            if (file.url.rawFile) {
                file.url = file.url.rawFile;
            }

            return file;
        })
        : [];

    data.date = data.date ? data.date.toString() : new Date().toString();

    if (data.deliveryDate) {
        data.deliveryDate = data.deliveryDate.toString();
    }

    return data;
};

export const useArrayInputStyles = makeStyles((theme) => ({
    line: {
        display: "flex",
        justifyContent: "center",
        listStyleType: "none",
        alignItems: "center",
        marginBottom: 10,
    },
}));

export const DecreaseIcon = (props) => {
    return (
        <button
            style={{minWidth: "2.5rem"}}
            className="btn btn-decrement btn-outline-secondary btn-minus"
            type="button"
            onClick={() => props.value > 1 && props.onChange(props.value - 1)}
        >
            <strong>-</strong>
        </button>
    );
};

export const IncreaseIcon = (props) => {
    return (
        <button
            style={{minWidth: "2.5rem"}}
            className="btn btn-increment btn-outline-secondary btn-minus"
            type="button"
            onClick={() => props.onChange(props.value + 1)}
        >
            <strong>+</strong>
        </button>
    );
};

const OrderCreateForm = (props) => {
    const redirect = useRedirect();
    const arrayInputClasses = useArrayInputStyles();
    const translate = useTranslate();

    return (
        <CreateFormContainer
            title=" "
            {...props}
            transform={transformOrderData}
            component={"div"}
            onSuccess={(newOrder) => {
                props.handleClose();
                redirect("show", "/orders", newOrder.data.id);
            }}
        >
            <SimpleForm
                validate={(values) => {
                    let errors = {};

                    if (
                        (!values.productsFiles ||
                            (values.productsFiles && values.productsFiles.length == 0)) &&
                        (!values.items || (values.items && values.items.length == 0))
                    ) {
                        errors["productsFiles"] =
                            "resources.orders.messages.picklist_or_additional_items_required";
                        errors["items"] =
                            "resources.orders.messages.picklist_or_additional_items_required";
                    }

                    return errors;
                }}
            >
                <TextInput
                    source="reference"
                    validate={[required()]}
                    placeholder={translate(
                        "resources.orders.messages.reference_placeholder"
                    )}
                />
                <DealerReferenceInput validate={[required()]}/>

                <FormGroup>
                    <DateInput
                        source="desiredDeliveryDate"
                        fullWidth
                        inputProps={{
                            min: DateTimeUtils.format(DateTimeUtils.addDays(new Date(), 1), "YYYY-MM-DD")
                        }}
                    />
                </FormGroup>

                <ArrayInput source="attachments">
                    <SimpleFormIterator
                        addButton={
                            <Button
                                label="resources.orders.actions.add_mockup"
                                variant="outline"
                            />
                        }
                        removeButton={
                            <Button
                                label="ra.action.delete"
                                variant="link"
                                color="danger"
                                style={{color: "red"}}
                            />
                        }
                        /* @ts-ignore */
                        classes={{
                            line: arrayInputClasses.line,
                        }}
                    >
                        <SimpleFileInput
                            source="url"
                            label="general.file"
                            validate={[required()]}
                            InputProps={{
                                placeholder: "resources.orders.messages.upload_mockup_file",
                            }}
                        />

                        <TextInput
                            source="description"
                            label="general.description"
                            validate={[required()]}
                            as="textarea"
                            rows={3}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="productsFiles">
                    <SimpleFormIterator
                        addButton={
                            <Button
                                label="resources.orders.actions.add_picklist"
                                variant="outline"
                            />
                        }
                        removeButton={
                            <Button
                                label="ra.action.delete"
                                variant="link"
                                color="danger"
                                style={{color: "red"}}
                            />
                        }
                        /* @ts-ignore */
                        classes={{
                            line: arrayInputClasses.line,
                        }}
                    >
                        <SimpleFileInput
                            source="url"
                            label="general.file"
                            validate={[required()]}
                            InputProps={{
                                placeholder: "resources.orders.messages.upload_picklist",
                            }}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="items">
                    <OrderAdditionalItemsIterator
                        addButton={
                            <Button
                                label="resources.orders.actions.add_product"
                                variant="outline"
                            />
                        }
                        removeButton={
                            <Button
                                label="ra.action.delete"
                                variant="link"
                                color="danger"
                                style={{color: "red"}}
                            />
                        }
                        productDetailsForm={
                            <>
                                <ProductListInput
                                    filterToQuery={(x) => {
                                        return {
                                            q: x,
                                            source: "exact",
                                            deleted: false
                                        }
                                    }}
                                />
                                <TextInput
                                    as="textarea"
                                    rows={3}
                                    source="note"
                                    label="resources.orders.fields.note"
                                />
                            </>
                        }
                        quantityFormItem={
                            <NumberInput
                                label="resources.orders.fields.quantity"
                                source="quantity"
                                validate={[required(), minValue(1)]}
                                InputProps={{
                                    startAdornment: <DecreaseIcon/>,
                                    endAdornment: <IncreaseIcon/>,
                                }}
                                min={1}
                                type="text"
                                initialValue={1}
                                style={{width: "3rem !important"}}
                            />
                        }
                    />
                </ArrayInput>

                <TextInput
                    as="textarea"
                    rows={3}
                    source="extraComments"
                />
            </SimpleForm>
        </CreateFormContainer>
    );
};

const OrderCreateModal = ({resource, basePath}) => {
    const {createActive, setCreateActive} = useOverviewContext();

    const handleClose = () => {
        setCreateActive(false);
    };
    const translate = useTranslate();

    return (
        <Modal
            show={createActive}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title as="h5">
                    {translate("resources.orders.titles.place_order")}
                </Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
            </Modal.Header>

            <Modal.Body>
                <OrderCreateForm
                    resource={resource}
                    basePath={basePath}
                    handleClose={handleClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default OrderCreateModal;
