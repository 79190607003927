import { apiUrl } from "@app";
import Button from "@main-components/buttons";
import LoadingButton from "@main-components/buttons/loading-button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState } from "react";
import {
  fetchEnd,
  fetchStart,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";
import { useDispatch } from "react-redux";

const AcceptRequestPasswordChangeButton = (props) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { record, resource } = props;

  const accept = async () => {
    setShowDialog(false);
    setIsLoading(true);

    const token = await localStorage.getItem("storevan_admin_token");

    dispatch(fetchStart());

    fetch(`${apiUrl}/dealers/accept-password-change-request`, {
      method: "POST",
      body: JSON.stringify({ dealerId: record.id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (res) => {
        const response = await res.json();

        if (res.status < 200 || res.status >= 300) {
          notify("ra.message.error", "warning");
        } else {
          notify("settings.accepted_request_password");
          redirect(`/dealers/${record.id}/show`);
        }
      })
      .catch((e) => {
        notify("ra.message.error", "warning");
      })
      .finally(() => {
        setShowDialog(false);
        setIsLoading(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <span>
      <LoadingButton
        label={translate(
          !record.passwordResetApprovedAt
            ? "settings.accept_request_password_change"
            : "settings.resend_password_change_reset_link"
        )}
        isLoading={isLoading}
        onClick={() => setShowDialog(true)}
        variant="outline"
        color="primary"
        startIcon={<CheckIcon />}
        className="mr-2"
      />

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate(
            !record.passwordResetApprovedAt
              ? "settings.accept_request_password_change"
              : "settings.resend_password_change_reset_link"
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("settings.accept_request_password_change_alert")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            label={translate("ra.action.cancel")}
            color="muted"
            variant="outline"
          />

          <Button
            onClick={() => accept()}
            label={translate("ra.action.confirm")}
          />
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default AcceptRequestPasswordChangeButton;
