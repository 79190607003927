import * as firebase from "firebase";
import { useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";

var app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DB_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
});

const useNotificationCount = () => {
  const [count, setCount] = useState(0);
  const { loading, identity } = useGetIdentity();
  useEffect(() => {
    if (!identity) return;
    app
      .firestore()
      .collection("AdminNotificationEntity")
      .where("seen", "==", 0)
      .where("adminId", "==", identity.id)
      .onSnapshot((data) => {
        setCount(data.size);
      });
  }, [identity, loading, count]);

  return { count };
};

export const useNotificationReset = () => {
  const { loading, identity } = useGetIdentity();

  const deleteNotifications = (callback) => {
    if (!identity) return;
    var batch = app.firestore().batch();

    app
      .firestore()
      .collection("AdminNotificationEntity")

      .where("adminId", "==", identity.id)
      .onSnapshot((data) => {
        try {
          data.docs.forEach((d) => {
            batch.delete(d.ref);
          });

          batch.commit().then(() => {
            callback();
          });
        } catch (e) {}
      });
  };

  return {
    deleteNotifications,
  };
};

export default useNotificationCount;
