// in src/Dashboard.js
import AppBarTitle from "@main-components/app-bar-title";
import * as React from "react";
import { usePermissions, useTranslate } from "react-admin";
import LastNewsWidget from "./widgets/last-news";
import NewsWidget from "./widgets/news";
import NotificationsWidget from "./widgets/notifications";
import OrdersWidget from "./widgets/orders";
import RealizationsWidget from "./widgets/realizations";
import StatsWidget from "./widgets/stats";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Dashboard = () => {
  const translate = useTranslate();

  const { loaded: permissionsLoaded, permissions } = usePermissions();

  if (!permissionsLoaded) return null;

  const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";

  return (
    <>
      <AppBarTitle title="Dashboard" />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1 id="react-admin-title">
          <small className="h4 text-muted order-2 ml-2">StoreVan Admin</small>
        </h1>{" "}
      </div>

      <div className="row">
        <div className="col-sm-6 col-lg-6 mb-4">
          <NotificationsWidget />

          {isAdmin && <RealizationsWidget isAdmin={isAdmin} />}

          <NewsWidget isAdmin={isAdmin} />
        </div>
        <div className="col-sm-6 col-lg-6 mb-4">
          {isAdmin && <StatsWidget />}

          <OrdersWidget isAdmin={isAdmin} />

          <LastNewsWidget isAdmin={isAdmin} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
