import { linkToRecord, Record } from "ra-core";
import * as React from "react";
import { FC, ReactElement } from "react";
import Button, { ButtonProps } from "..";

const EditButton: FC<EditButtonProps> = ({
  basePath = "",
  label = "ra.action.edit",
  record,
  icon = defaultIcon,
  ...rest
}) => (
  <Button
    link={!!!rest.onClick}
    to={linkToRecord(basePath, record && record.id)}
    label={label}
    onClick={(e) => {
      stopPropagation(e);
      rest.onClick && rest.onClick(e);
    }}
    {...(rest as any)}
  >
    {icon}
  </Button>
);
const Icon = () => <i className="fas fa-pencil mr-1"></i>;

const defaultIcon = <Icon />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps;

export default EditButton;
