import {withStyles} from "@material-ui/core";
import AutocompleteInput from "@main-components/inputs/autocomplete-input";
import {ReferenceInput, required, useTranslate} from "react-admin";
import * as React from "react";
import TextUtils from "@misc/utils/text";
import Badge from "@main-components/badge";


const ProductAutoCompleteInput = withStyles({
    suggestionsContainer: {maxWidth: 440},
})(AutocompleteInput);


function RenderOption(props) {
    const {record: x} = props
    const translate = useTranslate()
    return (
        <div className={"d-flex align-items-xxl-center"}>
            <div className={"mr-4"}>
                <img
                    src={props.record.imageUrl}
                    style={{width: 80, height: 80}}
                />
            </div>
            <div>
                <div>{x.name}</div>
                <div>
                <span
                    style={{fontSize: 12}}
                    className={"text-muted font-weight-bold"}
                >{TextUtils.numberToPrice(x.sellingPrice)} - {x.code}</span>
                </div>
                <div
                    style={{
                        marginTop: 4
                    }}
                >
                    {
                        x.inBackOrder ? (
                            <Badge
                                label={translate("resources.products.fields.inBackOrder")}
                                color={"warning"}
                            />
                        ) : (
                            <Badge
                                label={`${translate("resources.products.fields.inStock")}`}
                                color={"success"}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const inputText = choice => `${choice?.name} - ${choice?.code}`;

export function ProductListInput(props) {
    return (
        <ReferenceInput
            {...props}
            label="resources.orders.fields.productId"
            source={`${props.source}.productId`}
            reference="products"
            validate={[required()]}
        >
            <ProductAutoCompleteInput
                optionText={<RenderOption/>}
                validate={[required()]}
                options={{
                    suggestionsContainerProps: {
                        placement: "bottom",
                    },
                }}
                inputText={inputText}
                matchSuggestion={(filterValue, suggestion) => {
                    return true
                }}

            />
        </ReferenceInput>
    )
}