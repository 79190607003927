import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";
import { FC, Fragment, ReactElement } from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    "& a": {
      paddingLeft: theme.spacing(4),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  sidebarIsClosed: {
    "& a": {
      paddingLeft: theme.spacing(2),
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
}));

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
}

const SubMenu: FC<Props> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <li className="nav-item" onClick={handleToggle}>
      <span className="nav-link">
        {icon}
        {translate(name)}
        {
          <span style={{ position: "absolute", zIndex: 999, right: 10 }}>
            {isOpen ? (
              <i className="far fa-chevron-down fa-fw "></i>
            ) : (
              <i className="far fa-chevron-right fa-fw "></i>
            )}
          </span>
        }
      </span>
    </li>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <ul
          className={`nav flex-column ${
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }`}
        >
          {children}
        </ul>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
