import React from "react";
import WarehouseKeeperCreate from "./create";
import WarehouseKeeperEdit from "./edit";
import WarehouseKeeperList from "./list";
import WarehouseKeeperShow from "./show";

const resource = {
  list: WarehouseKeeperList,
  create: WarehouseKeeperCreate,
  edit: WarehouseKeeperEdit,
  show: WarehouseKeeperShow,
  icon: () => <i className="far fa-users-cog fa-fw"></i>,
};

export default resource;
