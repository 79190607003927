import DataGrid from "@main-components/data-grid";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {Filter, FunctionField, usePermissions, useTranslate} from "react-admin";
import ProductCreateModal from "@main/products/create";
import {useOverviewState} from "@main-components/list/utils/use-overview-context";
import OverviewContext from "@main-components/list/utils/overview-context";
import TextUtils from "@misc/utils/text";
import Badge from "@main-components/badge";
import {makeStyles} from "@material-ui/core/styles";
import NumberField from "@main-components/fields/number-field";


const MainActions = (props) => {
    return (
        <div>

        </div>
    );
};

const useStyles = makeStyles({
    centered: {
        textAlign: 'center',
    },
});


const ListContent = (props) => {
    const {count} = useResourceCount("products", {source: "exact", deleted: false});
    const translate = useTranslate();
    const {loaded: permissionsLoaded, permissions} = usePermissions();
    const classes = useStyles();

    const subHeader =
        count > 0
            ? `${count} ${translate("resources.products.name", {
                smart_count: count === 1 ? 1 : 2,
            })}`
            : "";

    if (!permissionsLoaded) return null;

    const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";
    const ListFilters = (props) => (
        <Filter {...props}>
            <TextInput
                source="q"
                alwaysOn
                label={false}
                placeholder={translate("general.search")}
                InputProps={{
                    startAdornment: (
                        <span
                            className="input-group-text h-100"
                            id="product-search-addon"
                        >
              <i
                  className="fal fa-search"
                  aria-hidden="true"
              ></i>
            </span>
                    ),
                }}
            />
        </Filter>
    );

    return (
        <>
            <ProductCreateModal
                resource={props.resource}
                basePath={props.basePath}
            />
            <List
                {...props}
                listSubHeader={subHeader}
                title={props.options.label}
                bulkActionButtons={false}
                exporter={false}
                mainActions={isAdmin ? <MainActions/> : <div></div>}
                component={ListWrapper}
                filters={<ListFilters/>}
                filter={{
                    source: "exact",
                    deleted: false
                }}
            >
                <DataGrid rowClick="show">
                    <FunctionField
                        source="imageUrl"
                        render={(record) => {
                            return <img
                                src={record.imageUrl}
                                style={{width: 100, height: 100}}
                                alt=""
                            />
                        }}
                    />

                    <TextField source="code"/>
                    <TextField source="name"/>
                    <TextField source="description"/>
                    <FunctionField
                        source="sellingPrice"
                        render={(record) => {
                            return TextUtils.numberToPrice(record.sellingPrice)
                        }}
                        headerClassName={classes.centered}
                        cellClassName={classes.centered}
                    />
                    <NumberField
                        source="stock"
                        headerClassName={classes.centered}
                        cellClassName={classes.centered}
                    />
                    <FunctionField
                        source="status"
                        render={(record) => {
                            return record.inBackOrder ? (
                                <Badge
                                    label={translate("resources.products.fields.inBackOrder")}
                                    color={"warning"}
                                />
                            ) : (
                                <Badge
                                    label={`${translate("resources.products.fields.inStock")}`}
                                    color={"success"}
                                />
                            )
                        }}
                    />
                </DataGrid>
            </List>
        </>

    );
};

const ProductList = (props) => {
    return (
        <OverviewContext.Provider value={{...useOverviewState()}}>
            <ListContent {...props} />
        </OverviewContext.Provider>
    );
};
export default ProductList;
