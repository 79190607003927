import {TextFieldProps} from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {FieldTitle, InputProps, useInput} from "ra-core";
import * as React from "react";
import {FunctionComponent} from "react";
import {translate} from "react-admin";
import {Form} from "react-bootstrap";
import BaseInput from "../BaseInput";
import sanitizeRestProps from "../sanitizeRestProps";

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const convertDateToString = (value: Date) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return;
    const pad = "00";
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

const getStringFromDate = (value: string | Date) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === "") {
        return "";
    }

    if (value instanceof Date) {
        return convertDateToString(value);
    }

    // valid dates should not be converted
    if (dateRegex.test(value)) {
        return value;
    }

    return convertDateToString(new Date(value));
};

const DateInput: FunctionComponent<InputProps<TextFieldProps> &
    Omit<TextFieldProps, "helperText" | "label" | "size">> = ({
    format = getStringFromDate,
    label,
    options,
    source,
    resource,
    helperText,
    margin = "dense",
    onBlur,
    onChange,
    onFocus,
    parse,
    validate,
    variant = "filled",
    InputProps,
    size = undefined,
    className,
    inputProps,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: {error, touched},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const hasError = !!(touched && error);

    return (
        <>
            {label !== "" && label !== false && (
                <Form.Label>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </Form.Label>
            )}

            <BaseInput
                id={id}
                input={input}
                size={size}
                type="date"
                InputProps={InputProps}
                error={error}
                touched={touched}
                className={className}
                inputProps={inputProps}
                {...sanitizeRestProps(rest)}
            />

            {hasError && (
                <Form.Control.Feedback
                    type="invalid"
                    style={{display: "block"}}
                >
                    {translate(error)}
                </Form.Control.Feedback>
            )}

            {!hasError && helperText && <Form.Text muted>{helperText}</Form.Text>}
        </>
    );
};

DateInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    resource: PropTypes.string,
    /* @ts-ignore */
    source: PropTypes.string,
};

DateInput.defaultProps = {
    options: {},
};

export default DateInput;
