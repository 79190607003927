import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup, {FormGroupProps} from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch, {SwitchProps} from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import {FieldTitle, InputProps, useInput} from "ra-core";
import * as React from "react";
import {FunctionComponent, useCallback} from "react";
import InputHelperText from "../InputHelperText";
import InputPropTypes from "../InputPropTypes";
import sanitizeRestProps from "../sanitizeRestProps";

const BooleanInput: FunctionComponent<
    InputProps<SwitchProps> &
    Omit<FormGroupProps, "defaultValue" | "onChange" | "onBlur" | "onFocus">
> = ({
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    ...rest
}) => {
    const {
        id,
        input: {onChange: finalFormOnChange, type, value, ...inputProps},
        isRequired,
        meta: {error, touched},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: "checkbox",
        validate,
        ...rest,
    });

    const handleChange = useCallback(
        (event, value) => {
            finalFormOnChange(value);
        },
        [finalFormOnChange]
    );

    return (
        <FormGroup {...sanitizeRestProps(rest)}>

            {
                !!label ? (
                    <FormControlLabel
                        control={
                            <Switch
                                id={id}
                                color="primary"
                                onChange={handleChange}
                                {...inputProps}
                                {...options}
                                disabled={disabled}
                            />
                        }
                        label={
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />
                        }
                    />
                ) : (
                    <Switch
                        id={id}
                        color="primary"
                        onChange={handleChange}
                        {...inputProps}
                        {...options}
                        disabled={disabled}
                    />
                )
            }

            <FormHelperText error={!!error}>
                <InputHelperText
                    /* @ts-ignore */
                    touched={touched}
                    error={error}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormGroup>
    );
};

/* @ts-ignore */
BooleanInput.propTypes = {
    ...InputPropTypes,
    // @ts-ignore
    options: PropTypes.shape(Switch.propTypes),
    disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
    options: {},
};

export default BooleanInput;
