import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import Downshift, { DownshiftProps } from "downshift";
import get from "lodash/get";
import { ChoicesInputProps, useInput, useSuggestions, warning } from "ra-core";
import React, {
  FunctionComponent,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldTitle, useTranslate } from "react-admin";
import { Form } from "react-bootstrap";
import BaseInput from "../BaseInput";
import sanitizeRestProps from "../sanitizeRestProps";
import AutocompleteSuggestionItem from "./AutocompleteSuggestionItem";
import AutocompleteSuggestionList from "./AutocompleteSuggestionList";

interface Options {
  suggestionsContainerProps?: any;
  labelProps?: any;
}

const AutocompleteInput: FunctionComponent<
  ChoicesInputProps<TextFieldProps & Options> &
    Omit<DownshiftProps<any>, "onChange">
> = (props) => {
  const {
    allowEmpty,
    className,
    classes: classesOverride,
    choices = [],
    disabled,
    emptyText,
    emptyValue,
    format,
    fullWidth,
    helperText,
    id: idOverride,
    input: inputOverride,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    margin = "dense",
    matchSuggestion,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    options: {
      suggestionsContainerProps,
      labelProps,
      InputProps,
      ...options
    } = {
      suggestionsContainerProps: undefined,
      labelProps: undefined,
      InputProps: undefined,
    },
    optionText = "name",
    inputText,
    optionValue = "id",
    parse,
    resource,
    setFilter,
    shouldRenderSuggestions: shouldRenderSuggestionsOverride,
    source,
    suggestionLimit,
    translateChoice = true,
    validate,
    variant = "filled",
    optionDescription = undefined,
    ...rest
  } = props;

  if (isValidElement(optionText) && !inputText) {
    throw new Error(`If the optionText prop is a React element, you must also specify the inputText prop:
        <AutocompleteInput
            inputText={(record) => record.title}
        />`);
  }

  warning(
    isValidElement(optionText) && !matchSuggestion,
    `If the optionText prop is a React element, you must also specify the matchSuggestion prop:
<AutocompleteInput
    matchSuggestion={(filterValue, suggestion) => true}
/>
        `
  );

  warning(
    source === undefined,
    `If you're not wrapping the AutocompleteInput inside a ReferenceInput, you must provide the source prop`
  );

  warning(
    choices === undefined,
    `If you're not wrapping the AutocompleteInput inside a ReferenceInput, you must provide the choices prop`
  );

  const classes = useStyles(props);

  let inputEl = useRef<HTMLInputElement>();
  let anchorEl = useRef<any>();

  const {
    id,
    input,
    isRequired,
    meta: { touched, error },
  } = useInput({
    format,
    id: idOverride,
    input: inputOverride,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    /* @ts-ignore */
    source,
    validate,
    ...rest,
  });

  const [filterValue, setFilterValue] = useState("");

  const getSuggestionFromValue = useCallback(
    (value) => choices.find((choice) => get(choice, optionValue) === value),
    [choices, optionValue]
  );

  const selectedItem = useMemo(
    () => getSuggestionFromValue(input.value) || null,
    [input.value, getSuggestionFromValue]
  );

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    allowEmpty,
    choices,
    emptyText,
    emptyValue,
    limitChoicesToValue,
    matchSuggestion,
    optionText,
    optionValue,
    selectedItem,
    suggestionLimit,
    translateChoice,
  });

  const handleFilterChange = useCallback(
    (eventOrValue: React.ChangeEvent<{ value: string }> | string) => {
      const event = eventOrValue as React.ChangeEvent<{ value: string }>;
      const value = event.target
        ? event.target.value
        : (eventOrValue as string);

      if (setFilter) {
        setFilter(value);
      }
    },
    [setFilter]
  );

  // We must reset the filter every time the value changes to ensure we
  // display at least some choices even if the input has a value.
  // Otherwise, it would only display the currently selected one and the user
  // would have to first clear the input before seeing any other choices
  useEffect(() => {
    handleFilterChange("");

    // If we have a value, set the filter to its text so that
    // Downshift displays it correctly
    setFilterValue(
      typeof input.value === "undefined" ||
        input.value === null ||
        selectedItem === null
        ? ""
        : inputText
        ? inputText(getChoiceText(selectedItem).props.record)
        : getChoiceText(selectedItem)
    );
  }, [input.value, handleFilterChange, selectedItem, getChoiceText, inputText]);

  const handleChange = useCallback(
    (item: any) => {
      input.onChange(getChoiceValue(item));
    },
    [getChoiceValue, input]
  );

  // This function ensures that the suggestion list stay aligned to the
  // input element even if it moves (because user scrolled for example)
  const updateAnchorEl = () => {
    if (!inputEl.current) {
      return;
    }

    const inputPosition = inputEl.current.getBoundingClientRect() as DOMRect;

    // It works by implementing a mock element providing the only method used
    // by the PopOver component, getBoundingClientRect, which will return a
    // position based on the input position
    if (!anchorEl.current) {
      anchorEl.current = { getBoundingClientRect: () => inputPosition };
    } else {
      const anchorPosition = anchorEl.current.getBoundingClientRect();

      if (
        anchorPosition.x !== inputPosition.x ||
        anchorPosition.y !== inputPosition.y
      ) {
        anchorEl.current = {
          getBoundingClientRect: () => inputPosition,
        };
      }
    }
  };

  const storeInputRef = (input) => {
    inputEl.current = input;
    updateAnchorEl();
  };

  const handleBlur = useCallback(
    (event) => {
      handleFilterChange("");

      // If we had a value before, set the filter back to its text so that
      // Downshift displays it correctly
      setFilterValue(
        input.value
          ? inputText
            ? inputText(getChoiceText(selectedItem).props.record)
            : getChoiceText(selectedItem)
          : ""
      );
      input.onBlur(event);
    },
    [getChoiceText, handleFilterChange, input, inputText, selectedItem]
  );

  const handleFocus = useCallback(
    (openMenu) => (event) => {
      openMenu(event);
      input.onFocus(event);
    },
    [input]
  );
  const translate = useTranslate();

  const shouldRenderSuggestions = (val) => {
    if (
      shouldRenderSuggestionsOverride !== undefined &&
      typeof shouldRenderSuggestionsOverride === "function"
    ) {
      return shouldRenderSuggestionsOverride(val);
    }

    return true;
  };

  const hasError = !!(touched && error);
  const isValid = !!(touched && !error);

  return (
    <Downshift
      inputValue={filterValue}
      onChange={handleChange}
      selectedItem={selectedItem}
      itemToString={(item) => getChoiceValue(item)}
      {...rest}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        openMenu,
      }) => {
        const isMenuOpen = isOpen && shouldRenderSuggestions(filterValue);
        const {
          id: downshiftId, // We want to ignore this to correctly link our label and the input
          value,
          onBlur,
          onChange,
          onFocus,
          ref,
          size,
          color,
          ...inputProps
        } = getInputProps({
          onBlur: handleBlur,
          onFocus: handleFocus(openMenu),
          ...InputProps,
        });
        const suggestions = getSuggestions(filterValue);

        return (
          <div className={classes.container}>
            {label !== "" && label !== false && (
              <Form.Label>
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              </Form.Label>
            )}

            <BaseInput
              id={id}
              input={input}
              size={size}
              onBlur={onBlur}
              onChange={(event) => {
                handleFilterChange(event);
                setFilterValue(event.target.value);
                onChange!(event as React.ChangeEvent<HTMLInputElement>);
              }}
              onFocus={onFocus}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end" style={{ height: "100%" }}>
                    <IconButton
                      aria-label={translate("ra.action.clear_input_value")}
                      title={translate("ra.action.clear_input_value")}
                      disableRipple
                      disabled={!!!input.value}
                      style={{ height: 24, width: 24, padding: 0 }}
                      onClick={() => {
                        handleChange(null);
                      }}
                    >
                      <ClearIcon
                        style={{
                          height: 23,
                          width: 23,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={error}
              touched={touched}
              className={className}
              inputRef={storeInputRef}
              value={filterValue}
              {...inputProps}
              {...sanitizeRestProps(rest)}
            />

            {hasError && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {translate(error)}
              </Form.Control.Feedback>
            )}

            {!hasError && helperText && (
              <Form.Text muted>{helperText}</Form.Text>
            )}

            <AutocompleteSuggestionList
              isOpen={isMenuOpen}
              menuProps={getMenuProps(
                {},
                // https://github.com/downshift-js/downshift/issues/235
                { suppressRefError: true }
              )}
              /* @ts-ignore */
              inputEl={inputEl.current}
              suggestionsContainerProps={suggestionsContainerProps}
              className={classes.suggestionsContainer}
              style={{ position: "relative" }}
            >
              {suggestions.map((suggestion, index) => (
                <AutocompleteSuggestionItem
                  key={getChoiceValue(suggestion)}
                  suggestion={suggestion}
                  index={index}
                  highlightedIndex={highlightedIndex}
                  isSelected={input.value === getChoiceValue(suggestion)}
                  filterValue={filterValue}
                  getSuggestionText={getChoiceText}
                  {...getItemProps({
                    item: suggestion,
                  })}
                  getSuggestionDescriptionText={optionDescription ? (suggestion) => {
                    return suggestion[optionDescription]
                  }: undefined}
                />
              ))}
            </AutocompleteSuggestionList>
          </div>
        );
      }}
    </Downshift>
  );
};

const useStyles = makeStyles(
  {
    container: {
      flexGrow: 1,
      position: "relative",
    },
    suggestionsContainer: {
      zIndex: 9999,
    },
  },
  { name: "RaAutocompleteInput" }
);

export default AutocompleteInput;
