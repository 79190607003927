import CreateButton from "@main-components/buttons/create-button";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import SelectField from "@main-components/fields/select-field";
import TextField from "@main-components/fields/text-field";
import SelectInput from "@main-components/inputs/select-input";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import * as React from "react";
import { Filter, TopToolbar } from "react-admin";

const AdminFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" label="general.email" />
    <SelectInput
      label="general.role"
      source="role"
      choices={[
        { id: "SUPERADMIN", name: "Super Admin" },
        { id: "ADMIN", name: "Admin" },
      ]}
    />
  </Filter>
);

const ListActions = (props) => {
  return (
    <TopToolbar className="m-0 p-0 pt-1">
      <AdminFilter context="button" />
    </TopToolbar>
  );
};

const MainActions = (props) => {
  return (
    <div>
      <CreateButton {...props} />
    </div>
  );
};

const AdminList = (props) => (
  <List
    {...props}
    title={props.options.label}
    sort={{ field: "createdAt", order: "desc" }}
    bulkActionButtons={false}
    exporter={false}
    // filters={<AdminFilter />}
    actions={false}
    mainActions={<MainActions />}
    component={ListWrapper}
  >
    <DataGrid rowClick="show">
      <TextField source="firstName" label="general.first_name" />
      <TextField source="lastName" label="general.last_name" />
      <TextField source="email" label="general.email" />
      <SelectField
        label="general.role"
        source="role"
        choices={[
          { id: "SUPERADMIN", name: "Super Admin" },
          { id: "ADMIN", name: "Admin" },
          { id: "WAREHOUSE_KEEPER", name: "Warehouse keeper" },
        ]}
      />
      <DateField source="createdAt" showTime label="general.created_at" />
    </DataGrid>
  </List>
);

export default AdminList;
