import TextField from "@main-components/fields/text-field";
import React, { FC } from "react";
import { ReferenceField, ReferenceFieldProps } from "react-admin";

interface Props {
  source?: string;
}

const DealerReferenceField: FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField source="dealer" reference="dealers" link="show" {...props}>
    <TextField source="identificationId" />
  </ReferenceField>
);

DealerReferenceField.defaultProps = {
  source: "dealer",
  addLabel: true,
};

export default DealerReferenceField;
