import SelectInput from "@main-components/inputs/select-input";
import React, { FC } from "react";
import { InputProps, ReferenceInput } from "react-admin";

interface Props {
  source?: string;
}

const DealerReferenceInput: FC<
  Props & Omit<Omit<InputProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceInput label="Dealer" source="dealer" reference="dealers" {...props}>
    <SelectInput
      optionText={(data) => {
        return `${data.brandName} (${data.identificationId})`;
      }}
    />
  </ReferenceInput>
);

DealerReferenceInput.defaultProps = {
  source: "dealer",
};

export default DealerReferenceInput;
