import Layout from "@main-components/layout";
import admins from "@main/admins";
import Dashboard from "@main/dashboard";
import dealers from "@main/dealers";
import downloadTypes from "@main/download-types";
import downloads from "@main/downloads";
import LoginPage from "@main/login";
import logs from "@main/logs";
import news from "@main/news";
import notifications from "@main/notifications";
import orders from "@main/orders";
import products from "@main/products";
import realizations from "@main/realizations";
import keepers from "@main/warehouse-keepers";
import AuthProvider from "@misc/auth-provider";
import customRoutes from "@misc/custom-routes";
import DataProvider from "@misc/data-provider";
import TranslationProvider from "@misc/translation-provider";
import theme from "@theme";
import {createBrowserHistory as createHistory} from "history";
import {omit} from "lodash";
import * as React from "react";
import {Admin, Resource} from "react-admin";
import useStartFirebase from "@misc/utils/use-start-firebase";
import ProductsShow from "@main/products/show";
import WebShopProductList from "@main/products/sync";

export const apiUrl = process.env.REACT_APP_API_URL;

function App() {
    const history = createHistory({
        basename: process.env.PUBLIC_URL,
    });
    console.log(process.env.NODE_ENV)
    console.log(apiUrl)
    useStartFirebase()

    return (
        <React.Fragment>
            <Admin
                theme={theme}
                history={history}
                authProvider={AuthProvider(`${apiUrl}/login`)}
                dataProvider={DataProvider(apiUrl)}
                loginPage={LoginPage}
                layout={Layout}
                //catchAll={NotFound}
                dashboard={Dashboard}
                i18nProvider={TranslationProvider}
                customRoutes={customRoutes}
            >
                {(permissions) => {
                    const isAdmin =
                        permissions === "SUPERADMIN" || permissions === "ADMIN";

                    return [
                        <Resource
                            options={{label: "resources.dealers.name"}}
                            name="dealers"
                            {...(isAdmin ? dealers : omit(dealers, ["list"]))}
                        />,
                        <Resource
                            options={{label: "resources.accounts.name"}}
                            name="accounts"
                            {...(permissions === "SUPERADMIN" ? admins : {})}
                        />,
                        <Resource
                            options={{label: "resources.warehouse-keepers.name"}}
                            name="warehouse-keepers"
                            {...(isAdmin ? keepers : {})}
                        />,
                        <Resource
                            options={{label: "resources.logs.name"}}
                            name="logs"
                            {...(isAdmin ? logs : omit(logs, ["list"]))}
                        />,
                        <Resource
                            options={{label: "app.notification.title"}}
                            name="notifications"
                            {...notifications}
                        />,

                        <Resource
                            options={{label: "resources.products.name"}}
                            name="products"
                            {...products}
                        />,

                        <Resource
                            options={{label: "resources.webshop-products.menu"}}
                            name="webshop-products"
                            {...(isAdmin ? {
                                list: WebShopProductList,
                                show: ProductsShow,
                                icon: () => <i className="far fa-desktop fa-fw"></i>,
                            } : {})}
                        />,

                        <Resource
                            options={{label: "resources.orders.name"}}
                            name="orders"
                            {...orders}
                        />,

                        <Resource // THIS IS DOWNLOAD ITEM
                            options={{label: "resources.downloads.name"}}
                            name="downloadCategory"
                            {...(isAdmin ? downloads : {})}
                        />,

                        <Resource // THIS IS DOWNLOAD CATEGORY
                            options={{label: "resources.downloads.name"}}
                            name="downloads"
                            {...(isAdmin ? downloadTypes : {})}
                        />,

                        <Resource
                            options={{label: "resources.news.name"}}
                            name="news"
                            {...news}
                        />,

                        <Resource
                            options={{label: "resources.realizations.name"}}
                            name="realizations"
                            {...(isAdmin ? realizations : omit(realizations, ["list"]))}
                        />,

                        <Resource
                            options={{label: "resources.settings.name"}}
                            name="settings"
                        />,
                        <Resource name="dashboard-orders"/>,
                        <Resource name="dashboard-realizations"/>,
                        <Resource name="dashboard-notifications"/>,
                        <Resource name="dashboard-news"/>,
                        <Resource name="company-info"/>,
                        <Resource name="profile"/>,
                        <Resource name="order-history"/>,
                        <Resource name="webshop-product-categories"/>,
                    ];
                }}
            </Admin>
        </React.Fragment>
    );
}

export default App;
