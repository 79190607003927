import Button from "@main-components/buttons";
import DeleteButton from "@main-components/buttons/delete-button";
import EditButton from "@main-components/buttons/edit-button";
import RichTextField from "@main-components/fields/rich-text-field";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {useOverviewState,} from "@main-components/list/utils/use-overview-context";
import DateTimeUtils from "@misc/utils/date-time-utils";
import * as React from "react";
import {usePermissions, useShowController} from "react-admin";
import NewsEditModal from "../edit";

const EditNewsButton = (props) => {
    const {setEditContext, setEditActive} = useOverviewContext();

    const activateEdit = (context: string | null) => {
        setEditActive(!!context);

        setEditContext(context ? context : undefined);
    };

    return (
        <EditButton
            {...props}
            onClick={() => activateEdit("details")}
            label="resources.news.actions.edit_post"
            className="ml-1"
            variant="outline"
        />
    );
};

const MainActions = (props) => {
    const {setCreateActive} = useOverviewContext();

    return (
        <div>
            <EditNewsButton {...props} />
            <DeleteButton
                {...props}
                undoable={false}
                variant="outline"
                resource="news"
                basePath="/news"
                className="ml-1"
            />
        </div>
    );
};

const ShowLayout = (props) => {
    const {record} = useShowController(props);
    const {loaded: permissionsLoaded, permissions} = usePermissions();

    if (!record) return null;

    if (!permissionsLoaded) return null;

    const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
                <h1>{record.title}</h1>
                {isAdmin && <MainActions record={record}/>}
            </div>
            <div className="row">
                <div className="col-xs-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <article className="blog-post">
                                {record && record.featuredImage ? (
                                    <img
                                        src={record.featuredImage}
                                        alt=""
                                        className="featured-image"
                                    />
                                ) : (
                                    <img
                                        src="/img/example.jpg"
                                        alt=""
                                        className="featured-image"
                                    />
                                )}
                                <span className="date d-block mb-5">
                  <b>
                    {DateTimeUtils.format(record.date, "DD/MM/YYYY")} door
                    StoreVan
                  </b>
                </span>

                                <div>
                                    <RichTextField
                                        record={record}
                                        source="content"
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                            "repeat( auto-fit, minmax(250px, 100px) )",
                                    }}
                                    className="mt-5"
                                >
                                    {record.gallery &&
                                        record.gallery.map((image) => {
                                            return (
                                                <div className="mr-2">
                                                    <img
                                                        src={image.url}
                                                        alt={image.url}
                                                        style={{width: "100%"}}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                                {isAdmin && (
                                    <Button
                                        className="mt-3"
                                        link
                                        to="/news"
                                        variant="outline"
                                        block={false}
                                        label="resources.news.actions.go_to_overview"
                                    />
                                )}
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <NewsEditModal
                basePath="/news"
                resource="news"
                id={record.id}
            />
        </div>
    );
};

const NewsShow = (props) => {
    return (
        <OverviewContext.Provider value={{...useOverviewState()}}>
            <ShowLayout {...props} />
        </OverviewContext.Provider>
    );
};

export default NewsShow;
