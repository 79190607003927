import DeleteButton from "@main-components/buttons/delete-button";
import EditFormContainer from "@main-components/forms/edit-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import TextInput from "@main-components/inputs/text-input";
import * as React from "react";
import { FieldProps, required, ShowButton, TopToolbar } from "react-admin";

const WarehouseKeeperTitle: React.FC<FieldProps> = ({ record }) => {
  return <span> {record ? `"${record.email}"` : ""}</span>;
};

const WarehouseKeeperEditActions = (props) => {
  return (
    <TopToolbar {...props}>
      <ShowButton
        record={props.data}
        basePath="/warehouse-keepers"
        // variant="outline"
        className="mr-1"
      />

      <DeleteButton
        {...props}
        undoable={false}
        record={props.data}
        basePath="/warehouse-keepers"
        variant="outline"
      />
    </TopToolbar>
  );
};
const WarehouseKeeperEdit = (props) => (
  <EditFormContainer
    {...props}
    title={<WarehouseKeeperTitle />}
    undoable={false}
    actions={<WarehouseKeeperEditActions />}
  >
    <SimpleForm redirect="show" undoable={false}>
      <TextInput
        source="firstName"
        label="general.first_name"
        validate={[required()]}
      />
      <TextInput
        source="lastName"
        label="general.last_name"
        validate={[required()]}
      />
    </SimpleForm>
  </EditFormContainer>
);

export default WarehouseKeeperEdit;
