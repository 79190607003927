import SelectField from "@main-components/fields/select-field";
import React, { FC } from "react";
import { SelectFieldProps, useTranslate } from "react-admin";

const ResourceTypeReferenceField: FC<Omit<
  Omit<SelectFieldProps, "source" | "choices">,
  "children"
>> = ({ record, label }) => {
  const translate = useTranslate();

  const Events = [
    {
      id: "DEALER",
      name: translate("resources.dealers.name", { smart_count: 1 }),
    },
    {
      id: "PRODUCT",
      name: translate("resources.products.name", { smart_count: 1 }),
    },
    {
      id: "REALIZATION",
      name: translate("resources.realizations.name", { smart_count: 1 }),
    },

    {
      id: "ORDER",
      name: translate("resources.orders.name", { smart_count: 1 }),
    },
    {
      id: "ADMIN",
      name: translate("resources.accounts.name", { smart_count: 1 }),
    },
  ];

  return record ? (
    <SelectField
      source="resource"
      choices={Events}
      record={record}
      label={label}
    />
  ) : null;
};

export default ResourceTypeReferenceField;

ResourceTypeReferenceField.defaultProps = {
  addLabel: true,
  label: "resources.logs.fields.resource",
};
