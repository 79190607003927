import { apiUrl } from "@app";
import Card from "@main-components/card";
import CardContent from "@main-components/card/components/card-content";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  useAuthenticated,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
}));

let authToken = localStorage.getItem("storevan_admin_token");

const syncProductsRequest = (apiUrl, token) => {
  return fetch(`${apiUrl}/products/synchronize`, {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  });
};

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

function getHashValue(key, location) {
  var matches = location.hash.match(new RegExp(key + "=([^&]*)"));
  return matches ? matches[1] : null;
}

const SyncProducts = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const translate = useTranslate();
  const classes = useStyles(props);
  const notify = useNotify();
  let query = useQueryParams();
  const redirect = useRedirect();

  const access_token = getHashValue("access_token", props.location);

  useEffect(() => {
    setLoading(true);
    syncProductsRequest(apiUrl, access_token)
      .then(async (res) => {
        const response = await res.json();

        if (res.status < 200 || res.status >= 300) {
          response.msg
            ? notify(`settings.${response.msg}`, "warning")
            : notify("ra.message.error", "warning");
        } else {
          if (response.ok) {
            setDone(true);
            setTimeout(() => {
              redirect("/products");
            }, 2000);
          } else {
            setDone(false);
            response.msg && notify(`settings.${response.msg}`, "warning");
          }
        }
      })
      .catch((e) => {
        notify("ra.message.error", "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [access_token, notify, redirect]);

  return (
    <Container fixed className={classes.container}>
      <Card>
        <CardContent>
          <Typography variant="h3" component="h3" align="center">
            {translate("resources.products.messages.product_synchronization")}
          </Typography>

          <Typography variant="body1" component="p" align="center">
            {!access_token
              ? translate("resources.products.messages.auth_error")
              : loading
              ? translate("resources.products.messages.syncing_products")
              : done
              ? translate("resources.products.messages.syncing_complete")
              : translate("resources.products.messages.syncing_error")}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SyncProducts;
