import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {useTranslate} from "react-admin";
import Button from "..";

const LoadingButton = ({
    label,
    isLoading,
    onClick,
    block,
    variant = "outline",
    ...rest
}: {
    isLoading?: boolean;
    [prop: string]: any;
}) => {
    const translate = useTranslate();

    return (
        <Button
            disabled={isLoading}
            onClick={onClick && onClick}
            variant={variant}
            {...rest}
            style={{...(rest.style || {}), display: block ? null : "inline-flex"}}
            block={block}
            label={label}
        >
            {isLoading ? (
                <CircularProgress
                    size={20}
                    style={{marginRight: 10, color: "black"}}
                />
            ) : (
                <div></div>
            )}
        </Button>
    );
};

export default LoadingButton;
