import moment from "moment";
import {formatDistance} from 'date-fns';
import nl from "date-fns/locale/nl"


const DateTimeUtils = {
    format: (date, formatString) => {
        return moment(date).format(formatString);
    },
    addDays: (a, days) => {
        return moment(a).clone().add(days, 'days').toDate();
    },
    ago(date: Date) {
        return formatDistance(date, new Date(), {addSuffix: true, locale: nl});
    },
};

export default DateTimeUtils;
