import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import {useEffect, useState} from "react";
import {required, useNotify, useRefresh, useTranslate} from "react-admin";
import {Modal} from "react-bootstrap";
import useFirestore from "@misc/utils/use-firestore";

export const transformProductData = (data) => {

    if (data.productList.rawFile) {
        data.productList = data.productList.rawFile;
    }

    return data;
};

const ProductCreateFrom = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const {progress} = useImportProgress()

    useEffect(() => {
        if (progress === 100) {
            props.handleClose();
            refresh();
        }
    }, [progress, props, refresh])
    return (
        <CreateFormContainer
            {...props}
            component={"div"}
            transform={(values) => transformProductData(values)}
            onSuccess={(newRecord) => {
                /* props.handleClose();*/
                refresh();
                /* @ts-ignore */
                /*  notify("resources.downloads.notifications.created", "success");*/
            }}
        >
            <SimpleForm>
                <SimpleFileInput
                    source="productList"
                    label="general.file"
                    validate={[required()]}
                    InputProps={{
                        placeholder: "general.select_file",
                    }}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />

                {progress > 0 && (
                    <div
                        className="progress w-100"
                        style={{height: 10}}
                    >
                        <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{width: progress === 0 ? "0%" : `${progress}%`}}
                        ></div>
                    </div>
                )}

            </SimpleForm>
        </CreateFormContainer>
    );
};

const ProductCreateModal = ({resource, basePath}) => {
    const {createActive, setCreateActive} = useOverviewContext();

    const handleClose = () => {
        setCreateActive(false);
    };
    const translate = useTranslate();
    return (
        <Modal
            show={createActive}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title as="h5">
                    {translate("resources.products.titles.create_product")}
                </Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
            </Modal.Header>

            <Modal.Body>
                <ProductCreateFrom
                    resource={resource}
                    basePath={basePath}
                    handleClose={handleClose}
                />
            </Modal.Body>
        </Modal>
    );
};


function useImportProgress() {
    const [progress, setProgress] = useState(0);
    const firestore = useFirestore()
    firestore.doc("importProgress/PROGRESS")
    .onSnapshot((snap) => {
        setProgress(snap.data()?.progress);
    });

    return {
        progress: progress
    }
}

export default ProductCreateModal;
