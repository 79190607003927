import CreateButton from "@main-components/buttons/create-button";
import ShowButton from "@main-components/buttons/show-button";
import Card from "@main-components/card";
import GridList from "@main-components/grid-list";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {useOverviewState,} from "@main-components/list/utils/use-overview-context";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {Filter, TextField, useTranslate} from "react-admin";
import DownloadCategoryCreateModal from "../create";
import Masonry from "react-masonry-css";

const DownloadFilter = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search"
            source="name"
            alwaysOn
        />
        {/*   <ReferenceInput source="categoryId" reference="downloadTypes">
      <SelectInput optionText="name" />
    </ReferenceInput> */}
    </Filter>
);

const GoIcon = () => {
    return <i
        className="fas fa-arrow-alt-circle-right"
        aria-hidden="true"
    />;
};

const MainActions = (props) => {
    const {setCreateActive} = useOverviewContext();
    return (
        <div>
            <CreateButton {...props} onClick={() => setCreateActive(true)}/>
        </div>
    );
};

const ListView = (props) => {
    const translate = useTranslate();
    const {count} = useResourceCount("downloadTypes");
    const {setCreateActive} = useOverviewContext();


    const Container = ({children}) => (
        <Masonry
            breakpointCols={{
                default: 3,
                1366: 3,
                1100: 3,
                700: 2,
                500: 1,
            }}
            className="row"
            columnClassName=""
        >
            {children}
        </Masonry>
    );


    return (
        <>
            <List
                {...props}
                resource="downloads"
                basePath="/downloads"
                title={props.options.label}
                sort={{field: "createdAt", order: "desc"}}
                bulkActionButtons={false}
                exporter={false}
                component={"div"}
                actions={false}
                mainActions={count > 0 && <MainActions/>}
                hasCreate={true}
                empty={
                    <Card
                        className="w-100 "
                        style={{height: 500}}
                    >
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                            <Empty
                                resourceOverride="downloadCategory"
                                actions={
                                    <CreateButton
                                        {...props}
                                        onClick={() => {
                                            setCreateActive(true);
                                        }}
                                    />
                                }
                            />
                        </Card.Body>
                    </Card>
                }
            >
                <GridList
                    width="lg"
                    cols={{xs: 1, sm: 3, md: 3, lg: 3, xl: 3}}
                    cellHeight={"auto"}
                    ContainerComponent={Container}
                    renderGridItem={(data: any) => (
                        <div
                            className="card mb-2 mr-2"
                            key={data.id}
                        >
                            <div
                                className="bd-placeholder-img card-img-top"
                                style={{
                                    width: "100%",
                                    height: "300px",
                                }}
                            >
                                {data.pictureUrl ? (
                                    <img
                                        src={data.pictureUrl}
                                        alt={data.pictureUrl}
                                        style={{width: "100%", height: "auto"}}
                                    />
                                ) : (
                                    <img
                                        src="/img/illustrations/portfolio.svg"
                                        style={{width: "100%", height: "auto"}}
                                    />
                                )}
                            </div>

                            <div className="card-body">
                                <h5 className="card-title">{data.name}</h5>

                                <p className="card-text">
                                    <TextField
                                        source="description"
                                        record={data}
                                    />
                                </p>
                                <ShowButton
                                    resource="downloadCategory"
                                    label={translate(
                                        "resources.downloadCategory.actions.show_category",
                                        {
                                            categoryName: data.name,
                                        }
                                    )}
                                    translate="no"
                                    record={data}
                                    basePath="downloadCategory"
                                    icon={<GoIcon/>}
                                    alignIcon={"right"}
                                />
                            </div>
                        </div>
                    )}
                />
            </List>
            <DownloadCategoryCreateModal
                resource={props.resource}
                basePath={props.basePath}
            />
        </>
    );
};

const DownloadCategoryList = (props) => {
    return (
        <OverviewContext.Provider value={{...useOverviewState()}}>
            <ListView {...props} />
        </OverviewContext.Provider>
    );
};

export default DownloadCategoryList;
