import Button from "@main-components/buttons";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ArrayInput from "@main-components/inputs/array-input";
import NumberInput from "@main-components/inputs/number-input";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import DealerReferenceInput from "@main/dealers/shared/dealer-reference-input";
import * as React from "react";
import {minValue, required, useEditController, useRefresh, useTranslate,} from "react-admin";
import {FormGroup, Modal} from "react-bootstrap";
import {useForm} from "react-final-form";
import {DecreaseIcon, IncreaseIcon, transformOrderData, useArrayInputStyles,} from "../create";
import OrderAdditionalItemsIterator from "../shared/order-additional-items-iterator.tsx";
import OrderStatusInput from "../shared/status-input";
import {ProductListInput} from "@main/orders/shared/product-list-input";

const InfoContent = (props) => {
    const {record} = props;
    const form = useForm();
    const [currentStatus, setCurrentStatus] = React.useState(
        record && record.status
    );

    const renderAdminComments = currentStatus === "NEED_MORE_INFO";

    const translate = useTranslate();


    return (
        <>
            <FormGroup>
                <DealerReferenceInput
                    validate={[required()]}
                    disabled
                />
            </FormGroup>

            <FormGroup>
                <TextInput
                    source="reference"
                    validate={[required()]}
                    placeholder={translate(
                        "resources.orders.messages.reference_placeholder"
                    )}
                    disabled
                />
            </FormGroup>

            <FormGroup>
                <OrderStatusInput
                    record={record}
                    validate={[required()]}
                    onChange={(status) => {
                        setCurrentStatus(status);
                    }}
                />
            </FormGroup>


            {renderAdminComments && (
                <FormGroup>
                    <TextInput
                        as="textarea"
                        source="adminComments"
                        validate={[required()]}
                    />
                </FormGroup>
            )}
        </>
    );
};

const AttachmentsContent = () => {
    const arrayInputClasses = useArrayInputStyles();

    return (
        <>
            <ArrayInput
                source="attachments"
                validate={[required()]}
            >
                <SimpleFormIterator
                    addButton={
                        <Button
                            label="resources.orders.actions.add_mockup"
                            variant="outline"
                        />
                    }
                    removeButton={
                        <Button
                            label="ra.action.delete"
                            variant="link"
                            color="danger"
                            style={{color: "red"}}
                        />
                    }
                    /* @ts-ignore */
                    classes={{
                        line: arrayInputClasses.line,
                    }}
                >
                    <SimpleFileInput
                        source="url"
                        label="general.file"
                        validate={[required()]}
                        InputProps={{
                            placeholder: "resources.orders.messages.upload_mockup_file",
                        }}
                    />

                    <TextInput
                        source="description"
                        label="general.description"
                        validate={[required()]}
                        as="textarea"
                        rows={3}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

const AdditionalItemsContent = () => {
    return (
        <>
            <ArrayInput
                source="items"
                validate={[required()]}
            >
                <OrderAdditionalItemsIterator
                    addButton={
                        <Button
                            label="resources.orders.actions.add_product"
                            variant="outline"
                        />
                    }
                    removeButton={
                        <Button
                            label="ra.action.delete"
                            variant="link"
                            color="danger"
                            style={{color: "red"}}
                        />
                    }
                    productDetailsForm={
                        <>
                            <ProductListInput
                                filterToQuery={(x) => {
                                    return {
                                        q: x,
                                        deleted: false
                                    }
                                }}
                            />
                            <TextInput
                                as="textarea"
                                rows={3}
                                source="note"
                                label="resources.orders.fields.note"
                            />
                        </>
                    }
                    quantityFormItem={
                        <NumberInput
                            label="resources.orders.fields.quantity"
                            source="quantity"
                            validate={[required(), minValue(1)]}
                            InputProps={{
                                startAdornment: <DecreaseIcon/>,
                                endAdornment: <IncreaseIcon/>,
                            }}
                            min={1}
                            type="text"
                            initialValue={1}
                            style={{width: "3rem !important"}}
                        />
                    }
                />
            </ArrayInput>
        </>
    );
};

const PickListsContent = () => {
    const arrayInputClasses = useArrayInputStyles();

    return (
        <>
            <ArrayInput
                source="productsFiles"
                validate={[required()]}
            >
                <SimpleFormIterator
                    addButton={
                        <Button
                            label="resources.orders.actions.add_picklist"
                            variant="outline"
                        />
                    }
                    removeButton={
                        <Button
                            label="ra.action.delete"
                            variant="link"
                            color="danger"
                            style={{color: "red"}}
                        />
                    }
                    /* @ts-ignore */
                    classes={{
                        line: arrayInputClasses.line,
                    }}
                >
                    <SimpleFileInput
                        source="url"
                        label="general.file"
                        validate={[required()]}
                        InputProps={{
                            placeholder: "resources.orders.messages.upload_picklist",
                        }}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

const ExtraCommentsContent = () => {
    return (
        <>
            <TextInput
                as="textarea"
                rows={3}
                source="extraComments"
            />
        </>
    );
};

export const OrderEditForm = (props) => {
    const refresh = useRefresh();
    const editControllerProps = useEditController({
        ...props,
        transform: transformOrderData,
        onSuccess: (order) => {
            props.handleClose();
            refresh();
        },
        undoable: false,
    });

    if (!editControllerProps.record) {
        return null;
    }

    const {context} = props;

    const renderFormContent = () => {
        switch (context) {
            case "basic_info":
                return <InfoContent record={editControllerProps.record}/>;
            case "pick_lists":
                return <PickListsContent/>;
            case "mockups":
                return <AttachmentsContent/>;
            case "additional_products":
                return <AdditionalItemsContent/>;
            case "comments":
                return <ExtraCommentsContent/>;
            default:
                return null;
        }
    };
    return (
        <SimpleForm {...editControllerProps} redirect="list">
            {renderFormContent()}
        </SimpleForm>
    );
};

export const OrderEditModal = ({resource, basePath, id}) => {
    const {editActive, setEditActive, setEditContext, editContext} =
        useOverviewContext();

    const handleClose = () => {
        setEditActive(false);
        setEditContext(undefined);
    };
    const translate = useTranslate();

    return (
        <Modal
            show={editActive}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title as="h5">
                    {translate("resources.orders.titles.edit_order")}
                </Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
            </Modal.Header>

            <Modal.Body>
                <OrderEditForm
                    resource={resource}
                    basePath={basePath}
                    id={id}
                    handleClose={handleClose}
                    context={editContext}
                />
            </Modal.Body>
        </Modal>
    );
};
