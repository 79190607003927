import React from "react";
import LogList from "./list";
import LogShow from "./show";

const resource = {
  list: LogList,
  show: LogShow,
  icon: () => <i className="far fa-stream fa-fw"></i>,
};

export default resource;
