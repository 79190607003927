import SaveButton from "@main-components/buttons/save-button";
import SimpleForm from "@main-components/forms/simple-form";
import TextInput from "@main-components/inputs/text-input";
import * as React from "react";
import {
  required,
  Toolbar,
  useAuthenticated,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Card } from "react-bootstrap";

const ShowCompany = (props) => {
  useAuthenticated();
  const { loaded, loading, identity } = useGetIdentity();
  const translate = useTranslate();

  if (!loaded || !identity) return null;

  return <ShowCompanyContent id={identity.id} />;
};

function ShowCompanyContent({ id }) {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const editControllerProps = useEditController({
    id: id,
    resource: "company-info",
    basePath: "/company-info",
    undoable: false,
    onSuccess: () => {
      notify("resources.company-info.messages.updated_successfully");
      refresh();
    },
  });

  if (editControllerProps.record && editControllerProps.record.id) {
    delete editControllerProps.record.id;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1>{translate("general.company_info", { smart_count: 2 })}</h1>
      </div>

      <div className="row">
        <div className="col-xs-12 col-md-6 col-xl-6 mb-4">
          <Card>
            <Card.Header>
              <i className="far fa-garage-open mr-2"></i> StoreVan Vlaanderen
            </Card.Header>
            <Card.Body>
              <SimpleForm
                {...editControllerProps}
                undoable={false}
                toolbar={
                  <Toolbar>
                    <SaveButton label="general.save_changes" />
                  </Toolbar>
                }
              >
                <TextInput
                  source="name"
                  label="resources.company-info.fields.name"
                  validate={required()}
                  /* placeholder="Kadicon BV" */
                />
                <TextInput
                  source="streetAndNumber"
                  label="resources.company-info.fields.streetAndNumber"
                  validate={required()}
                  /* placeholder="Peperstraat 23" */
                />
                <TextInput
                  source="postCodeAndCity"
                  label="resources.company-info.fields.postCodeAndCity"
                  validate={required()}
                  /* placeholder="9230 Wetteren (BE)" */
                />

                <TextInput
                  source="vatNumber"
                  label="resources.company-info.fields.vatNumber"
                  validate={required()}
                  /* placeholder="BE 0123.456.789" */
                />
                <TextInput
                  source="website"
                  label="resources.company-info.fields.website"
                  validate={required()}
                  /* placeholder="https://storevan.vlaanderen" */
                />
              </SimpleForm>
            </Card.Body>
          </Card>
        </div>
        <div className="col-xs-12 col-md-6 col-xl-6 mb-4">
          <Card>
            <Card.Header>
              <i className="far fa-briefcase mr-2"></i>
              {translate("resources.company-info.titles.representative")}
            </Card.Header>
            <Card.Body>
              <SimpleForm
                {...editControllerProps}
                undoable={false}
                toolbar={
                  <Toolbar>
                    <SaveButton label="general.save_changes" />
                  </Toolbar>
                }
              >
                <TextInput
                  source="representative.fullName"
                  label="resources.company-info.fields.representative.fullName"
                  validate={required()}
                />
                <TextInput
                  source="representative.position"
                  label="resources.company-info.fields.representative.position"
                  /*  validate={required()} */
                />
                <TextInput
                  source="representative.phone"
                  label="resources.company-info.fields.representative.phone"
                  /*  validate={required()} */
                />
                <TextInput
                  source="representative.email"
                  label="resources.company-info.fields.representative.email"
                  /*  validate={required()} */
                />
              </SimpleForm>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ShowCompany;
