import CreateButton from "@main-components/buttons/create-button";
import DeleteButton from "@main-components/buttons/delete-button";
import EditButton from "@main-components/buttons/edit-button";
import Card from "@main-components/card";
import DataGrid from "@main-components/data-grid";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import Confirm from "@main-components/layout/Confirm";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {
  useOverviewState,
} from "@main-components/list/utils/use-overview-context";
import DownloadCategoryEditModal from "@main/download-types/edit";
import inflection from "inflection";
import * as React from "react";
import {
  Filter,
  FunctionField,
  useDeleteWithConfirmController,
  useReference,
  useTranslate,
} from "react-admin";
import { useSelector } from "react-redux";
import DownloadCreateModal from "../create";
import DownloadEditModal from "../edit";

const CatNameText = ({ id }) => {
  const { loaded, referenceRecord } = useReference({
    id: id,
    reference: "downloads",
  });

  if (!loaded) {
    return null;
  }

  return <>{referenceRecord?.name}</>;
};

const EditCategoryButton = (props) => {
  const { setEditContext, setEditActive } = useOverviewContext();

  const activateEdit = (context: string | null) => {
    setEditActive(!!context);

    setEditContext(context ? context : undefined);
  };

  return (
    <EditButton
      {...props}
      onClick={() => activateEdit("details")}
      label="resources.downloads.actions.edit_category"
      className="ml-1"
      variant="outline"
    />
  );
};

const EditDownloadButtonLink = (props) => {
  const { setEditContext, setEditActive } = useOverviewContext();
  const translate = useTranslate();

  const activateEdit = (context: string | null) => {
    setEditActive(!!context);

    setEditContext(context ? context : undefined);
    props.setActiveDownloadId(props.id);
  };

  return (
    <>
      <i className="fal fa-pencil ml-1" aria-hidden="true"></i>{" "}
      <a
        href="#"
        onClick={() => activateEdit("edit_download")}
        title="Bewerk dit bestand"
        id={props.id}
      >
        {translate("app.action.edit")}
      </a>
    </>
  );
};

const EditDeleteButtonLink = (props) => {
  const translate = useTranslate();
  const resource = "downloadCategory";
  const { open, loading, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      /* @ts-ignore */
      resource: resource,
      record: { id: props.id },
      redirect: false,
      basePath: "/downloadCategory",
    });

  const confirmTitle = "ra.message.delete_title";
  const confirmContent = "ra.message.delete_content";

  return (
    <>
      <i className="fal fa-trash-alt ml-1" aria-hidden="true"></i>{" "}
      <a href="#" onClick={handleDialogOpen} title="Verwijder dit bestand">
        {translate("app.action.delete")}
      </a>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.downloads.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.downloads.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true
            ),
          }),
          /* @ts-ignore */
          id: props.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};

const MainActions = (props) => {
  const { setCreateActive } = useOverviewContext();
  return (
    <div>
      <CreateButton
        {...props}
        onClick={() => setCreateActive(true)}
        label="resources.downloads.actions.upload_resource"
      />
      <EditCategoryButton {...props} />
      <DeleteButton
        {...props}
        basePath="/downloads"
        resource="downloads"
        record={{ id: props.categoryId }}
        undoable={false}
        variant="outline"
        className="ml-1"
        resourceMessage="downloadCategory"
      />
    </div>
  );
};

const CountSubtitle = ({ id }) => {
  const translate = useTranslate();
  const resource = useSelector(
    (state: any) => state.admin.resources.downloadCategory
  );

  const { loaded, referenceRecord } = useReference({
    id: id,
    reference: "downloads",
  });

  if (!loaded) {
    return null;
  }

  return (
    <p className="font-weight-bold mb-0">
      {translate("resources.downloads.messages.downloads_in_category", {
        total: resource.list.total || 0,
        cateName: referenceRecord?.name,
      })}
    </p>
  );
};

const ShowView = (props) => {
  const translate = useTranslate();

  const { setCreateActive } = useOverviewContext();
  const [activeDownloadId, setActiveDownloadId] = React.useState(0);

  if (!props.match.params.id) {
    return null;
  }

  const ListFilters = (props) => (
    <Filter {...props}>
      <TextInput
        source="q"
        alwaysOn
        label={false}
        placeholder={translate("general.search")}
        InputProps={{
          startAdornment: (
            <span className="input-group-text h-100" id="product-search-addon">
              <i className="fal fa-search" aria-hidden="true"></i>
            </span>
          ),
        }}
      />
    </Filter>
  );

  return (
    <>
      <List
        {...props}
        resource="downloadCategory"
        basePath="/downloadCategory"
        //title={null}
        sort={{ field: "createdAt", order: "desc" }}
        bulkActionButtons={false}
        exporter={false}
        filter={{
          categoryId: props.match.params.id,
        }}
        hasCreate={true}
        component={ListWrapper}
        filters={<ListFilters />}
        actions={false}
        mainActions={<MainActions categoryId={props.match.params.id} />}
        title={
          <>
            <CatNameText id={props.match.params.id} />{" "}
            <small className="h4 text-muted">
              {translate(props.options.label, { smart_count: 2 })}
            </small>
          </>
        }
        listSubHeader={<CountSubtitle id={props.match.params.id} />}
        empty={
          <Card className="w-100" style={{ height: 500 }}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Empty
                resourceOverride="downloads"
                actions={
                  <CreateButton
                    {...props}
                    onClick={() => {
                      setCreateActive(true);
                    }}
                  />
                }
              />
            </Card.Body>
          </Card>
        }
        //filters={<DownloadFilter />}
      >
        <DataGrid>
          <TextField source="title" />
          <TextField source="description" />
          <FunctionField
            source="type"
            render={(record) => {
              var fileExt =
                record && record.file ? record.file.split(".").pop() : "";

              return fileExt;
            }}
          />

          <FunctionField
            render={(record) => {
              return (
                <>
                  <i className="fal fa-download" aria-hidden="true"></i>{" "}
                  <a
                    href={record.file}
                    target="__BLANK"
                    title={translate("general.download_file")}
                  >
                    {translate("general.download")}
                  </a>
                  <EditDownloadButtonLink
                    setActiveDownloadId={setActiveDownloadId}
                    id={record.id}
                  />
                  <EditDeleteButtonLink id={record.id} />
                </>
              );
            }}
          />
        </DataGrid>
      </List>
      <DownloadCreateModal
        resource={props.resource}
        basePath={props.basePath}
        categoryId={props.match.params.id}
      />
      <DownloadCategoryEditModal
        resource="downloads"
        basePath="/downloads"
        id={props.match.params.id}
      />

      <DownloadEditModal
        resource="downloadCategory"
        basePath="/downloadCategory"
        id={activeDownloadId}
        categoryId={props.match.params.id}
      />
    </>
  );
};

const DownloadCategoryShow = (props) => {
  return (
    <OverviewContext.Provider value={{ ...useOverviewState() }}>
      <ShowView {...props} />
    </OverviewContext.Provider>
  );
};

export default DownloadCategoryShow;
