import EditFormContainer from "@main-components/forms/edit-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { required, useNotify, useRefresh, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";
import { transformDownloadCategoryData } from "../create";

const DownloadCategoryEditFrom = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <EditFormContainer
      {...props}
      id={props.id}
      undoable={false}
      component={"div"}
      transform={transformDownloadCategoryData}
      onSuccess={(newRecord) => {
        props.handleClose();
        refresh();
        /* @ts-ignore */
        notify("resources.downloadCategory.notifications.edited", "success");
      }}
    >
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />

        <TextInput
          source="description"
          label="resources.downloadCategory.fields.description"
          as="textarea"
        />

        <SimpleFileInput
          source="pictureUrl"
          label="general.file"
          InputProps={{
            placeholder: "resources.downloadCategory.messages.upload_image",
          }}
        />
      </SimpleForm>
    </EditFormContainer>
  );
};

const DownloadCategoryEditModal = ({ resource, basePath, id }) => {
  const {
    setEditActive,
    editContext,
    editActive,
    setEditContext,
  } = useOverviewContext();

  const handleClose = () => {
    setEditActive(false);
    setEditContext(undefined);
  };
  const translate = useTranslate();

  return (
    <Modal
      show={editActive && editContext === "details"}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.downloadCategory.titles.edit_category")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <DownloadCategoryEditFrom
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
          id={id}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DownloadCategoryEditModal;
