import SelectField from "@main-components/fields/select-field";
import React, { FC } from "react";
import { SelectFieldProps, useTranslate } from "react-admin";

const EventReferenceField: FC<Omit<
  Omit<SelectFieldProps, "source" | "choices">,
  "children"
>> = ({ record, label }) => {
  const translate = useTranslate();

  const Events = [
    {
      id: "ADMIN_USER_PASSWORD_CHANGED",
      name: translate(`resources.logs.events.ADMIN_USER_PASSWORD_CHANGED`, {
        smart_count: 1,
      }),
    },
    {
      id: "ADMIN_DEALER_CREATED",
      name: translate(`resources.logs.events.ADMIN_DEALER_CREATED`, {
        smart_count: 1,
      }),
    },
    {
      id: "ORDER_CREATED",
      name: translate(`resources.logs.events.ORDER_CREATED`, {
        smart_count: 1,
      }),
    },
    {
      id: "ORDER_MARKED_AS_INCOMPLETE",
      name: translate(`resources.logs.events.ORDER_MARKED_AS_INCOMPLETE`, {
        smart_count: 1,
      }),
    },
    {
      id: "ORDER_DETAILS_UPDATED",
      name: translate(`resources.logs.events.ORDER_DETAILS_UPDATED`, {
        smart_count: 1,
      }),
    },

    {
      id: "USER_PASSWORD_CHANGED",
      name: translate(`resources.logs.events.USER_PASSWORD_CHANGED`, {
        smart_count: 1,
      }),
    },
    {
      id: "REALIZATION_CREATED",
      name: translate(`resources.logs.events.REALIZATION_CREATED`, {
        smart_count: 1,
      }),
    },
    {
      id: "PRODUCTS_SYNCED",
      name: translate(`resources.logs.events.PRODUCTS_SYNCED`, {
        smart_count: 1,
      }),
    },
  ];

  return record ? (
    <SelectField
      source="event"
      choices={Events}
      record={record}
      label={label}
    />
  ) : null;
};

export default EventReferenceField;

EventReferenceField.defaultProps = {
  addLabel: true,
  label: "resources.logs.fields.event",
};
