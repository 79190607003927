import Button from "@main-components/buttons";
import ImageField from "@main-components/fields/image-field";
import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ArrayInput from "@main-components/inputs/array-input";
import BooleanInput from "@main-components/inputs/boolean-input";
import ImageInput from "@main-components/inputs/image-input";
import RichTextInput from "@main-components/inputs/rich-text-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import { useArrayInputStyles } from "@main/orders/create";
import * as React from "react";
import { required, useNotify, useRedirect, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";

export const transformNewsData = (data) => {
  if (data.featuredImage && data.featuredImage.rawFile) {
    data.featuredImage = data.featuredImage.rawFile;
  }

  data.gallery = data.gallery
    ? data.gallery.map((file) => {
        if (file.url.rawFile) {
          file.url = file.url.rawFile;
        }

        return file;
      })
    : [];

  return data;
};

const NewsCreateForm = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const arrayInputClasses = useArrayInputStyles();

  return (
    <CreateFormContainer
      {...props}
      transform={transformNewsData}
      component={"div"}
      onSuccess={(record) => {
        props.handleClose();
        redirect("show", "/news", record.data.id);
        /* @ts-ignore */
        notify("resources.news.notifications.created", "success");
      }}
    >
      <SimpleForm redirect="list">
        <TextInput source="title" validate={[required()]} />
        <RichTextInput
          validate={[required()]}
          source="content"
          toolbar={[
            ["bold", "italic", "underline", "link"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ font: [] }],
          ]}
        />
        <ImageInput source="featuredImage" accept="image/jpeg,image/png">
          <ImageField source="src" />
        </ImageInput>
        <BooleanInput source="pinned" defaultValue={false} />

        <ArrayInput source="gallery">
          <SimpleFormIterator
            addButton={
              <Button
                label="resources.news.actions.add_extra_image"
                variant="outline"
              />
            }
            removeButton={
              <Button
                label="ra.action.delete"
                variant="link"
                color="danger"
                style={{ color: "red" }}
              />
            }
            /* @ts-ignore */
            classes={{
              line: arrayInputClasses.line,
            }}
          >
            <ImageInput
              source="url"
              label={false}
              accept="image/jpeg,image/png"
            >
              <ImageField source="src" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </CreateFormContainer>
  );
};

const NewsCreateModal = ({ resource, basePath }) => {
  const { createActive, setCreateActive } = useOverviewContext();

  const handleClose = () => {
    setCreateActive(false);
  };
  const translate = useTranslate();

  return (
    <Modal show={createActive} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.news.titles.create_post")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <NewsCreateForm
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default NewsCreateModal;
