import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import TextInput from "@main-components/inputs/text-input";
import { validatePasswords } from "@main/reset-password";
import * as React from "react";
import { required } from "react-admin";

const WarehouseKeeperCreate = (props) => (
  <CreateFormContainer title="Create a new Admin account" {...props}>
    <SimpleForm
      redirect="list"
      validate={validatePasswords}
      defaultValue={{
        "general.role": "WAREHOUSE_KEEPER",
      }}
    >
      <TextInput source="email" validate={[required()]} label="general.email" />
      <TextInput
        source="firstName"
        validate={[required()]}
        label="general.first_name"
      />
      <TextInput
        source="lastName"
        validate={[required()]}
        label="general.last_name"
      />
      <TextInput
        source="password"
        validate={[required()]}
        label="general.password"
      />

      <TextInput
        source="confirmPassword"
        validate={[required()]}
        label="general.confirm_password"
      />

      {/*  <SelectInput
        label="general.role"
        validate={[required()]}
        source="role"
         choices={[
          { id: "SUPERADMIN", name: "Super Admin" },
          { id: "ADMIN", name: "Admin" },
          { id: "WAREHOUSE_KEEPER", name: "Warehouse keeper" },
        ]}
      /> */}
    </SimpleForm>
  </CreateFormContainer>
);

export default WarehouseKeeperCreate;
