import CreateButton from "@main-components/buttons/create-button";
import Card from "@main-components/card";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {useOverviewState,} from "@main-components/list/utils/use-overview-context";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {Filter, ReferenceField, useTranslate} from "react-admin";
import RealizationCreateModal from "../create";
import AvatarField from "@main-components/avatar-field";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const RealizationsMainActions = (props) => {
    const {setCreateActive} = useOverviewContext();
    return (
        <div>
            <CreateButton {...props} onClick={() => setCreateActive(true)}/>
        </div>
    );
};

const ListFilters = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                source="q"
                alwaysOn
                label={false}
                placeholder={translate("general.search")}
                InputProps={{
                    startAdornment: (
                        <span
                            className="input-group-text h-100"
                            id="product-search-addon"
                        >
              <i
                  className="fal fa-search"
                  aria-hidden="true"
              ></i>
            </span>
                    ),
                }}
            />
        </Filter>
    );
};

const RealizationListView = (props) => {
    const translate = useTranslate();
    const {count} = useResourceCount("realizations");
    const {setCreateActive} = useOverviewContext();

    const subHeader =
        count > 0
            ? `${count} ${translate("resources.realizations.name", {
                smart_count: count === 1 ? 1 : 2,
            })}`
            : "";

    return (
        <>
            <List
                {...props}
                title={props.options.label}
                listSubHeader={subHeader}
                sort={{field: "createdAt", order: "desc"}}
                bulkActionButtons={false}
                exporter={false}
                filters={<ListFilters/>}
                component={ListWrapper}
                mainActions={count > 0 && <RealizationsMainActions/>}
                hasCreate={true}
                actions={false}
                empty={
                    <Card
                        className="w-100"
                        style={{height: 500}}
                    >
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                            <Empty
                                resourceOverride="realizations"
                                icon={
                                    <div>
                                        <img
                                            src="/img/illustrations/portfolio.svg"
                                            className="w-100"
                                        />
                                    </div>
                                }
                                actions={
                                    <CreateButton
                                        {...props}
                                        onClick={() => {
                                            setCreateActive(true);
                                        }}
                                    />
                                }
                            />
                        </Card.Body>
                    </Card>
                }
            >
                <DataGrid rowClick="show">
                    <Avatar
                        addLabel
                        label={"Foto"}
                    />
                    <TextField source="title"/>
                    <ReferenceField
                        source="dealer"
                        reference="dealers"
                        link={false}
                        basePath="/"
                    >
                        <TextField source="identificationId"/>
                    </ReferenceField>
                    <DateField source="finishingDate"/>

                    <ReferenceField
                        source="order"
                        reference="orders"
                        link={false}
                        basePath="/orders"
                    >
                        <TextField source="orderId"/>
                    </ReferenceField>
                    <DateField
                        source="createdAt"
                        showTime
                        label="general.created_at"
                    />
                </DataGrid>
            </List>
            <RealizationCreateModal
                resource={props.resource}
                basePath={props.basePath}
            />
        </>
    );
};


const useStyles = makeStyles(() => createStyles({
    root: {
        width: 200, height: 200,
        borderRadius: 0
    },
}));


function Avatar(props) {
    const style = useStyles()
    if (!props.record) return <div/>
    return <AvatarField
        size={"lg"}
        className={style.root}
        source={"thumbnail"}
        record={{...props.record, thumbnail: getDefaultPicture(props.record.gallery)}}
    />
}

function getDefaultPicture(gallery: { url: string, isDefault: boolean }[]) {
    if (gallery.length === 0) return "/img/illustrations/portfolio.svg"

    const defaultImage = gallery.find((element) => element.isDefault)

    return defaultImage ? defaultImage.url : gallery[0].url
}

const RealizationList = (props) => {
    return (
        <OverviewContext.Provider
            value={{
                ...useOverviewState({
                    openCreate: props.location.search === "?newRealization=true",
                }),
            }}
        >
            <RealizationListView {...props} />
        </OverviewContext.Provider>
    );
};
export default RealizationList;
