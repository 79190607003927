import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import * as React from "react";
import { useTranslate } from "react-admin";

const LogList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={props.options.label}
      sort={{ field: "occurredOn", order: "desc" }}
      bulkActionButtons={false}
      exporter={false}
      component={ListWrapper}
    >
      <DataGrid>
        {/* <ResourceTypeReferenceField />
        <EventReferenceField />
        <ResourceReferenceField />
 */}

        <TextField source="message" label="resources.logs.fields.resourceId" />

        <DateField source="occurredOn" showTime />
      </DataGrid>
    </List>
  );
};
export default LogList;
