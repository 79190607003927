import Button from "@main-components/buttons";
import Card from "@main-components/card";
import Datagrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import RichTextField from "@main-components/fields/rich-text-field";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import {useMediaQuery} from "@material-ui/core";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {useState} from "react";
import {useDataProvider, useTranslate} from "react-admin";
import {useSelector} from "react-redux";

const EmptyIcon = (props) => (
    <i
        className={`fal fa-bell-exclamation mr-2`}
        style={{fontSize: "7.5em"}}
        aria-hidden="true"
    ></i>
);
export default function NotificationsWidget() {
    const [state, setState] = useState<any>({});
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
    const translate = useTranslate();
    const {count} = useResourceCount("notifications");
    const dashboardTotal = useSelector((state: any) => {
        return state.admin.resources["dashboard-notifications"]?.list?.total;
    });

    return (
        <Card className="mb-5">
            <Card.Header>
                <i
                    className="fal fa-bell-exclamation mr-2"
                    aria-hidden="true"
                ></i>
                {translate("app.notification.title")}
            </Card.Header>
            <Card.Body>
                <List
                    resource="dashboard-notifications"
                    basePath="/notifications"
                    hasCreate={true}
                    hasEdit={false}
                    hasList={true}
                    hasShow={true}
                    title=" "
                    sort={{field: "createdAt", order: "desc"}}
                    bulkActionButtons={false}
                    exporter={false}
                    component={"div"}
                    actions={false}
                    mainActions={false}
                    pagination={false}
                    /*   filter={{
                      seen: false,
                    }} */
                    perPage={5}
                    empty={
                        <Empty
                            hideActions
                            resourceOverride="notifications"
                            basePath="/notifications"
                            subtitle="app.notification.empty_message"
                            icon={
                                <div>
                                    <img
                                        src="/img/illustrations/notifications.svg"
                                        className="w-100"
                                    />
                                </div>
                            }
                        />
                    }
                >
                    <Datagrid resource="notifications">
                        <DateField
                            source="createdAt"
                            sortable={false}
                            showTime
                            label="general.created_at"
                        />
                        <RichTextField
                            source="content"
                            sortable={false}
                        />
                    </Datagrid>
                </List>
                <div
                    className={`mt-4  mb-4 ${
                        dashboardTotal === 0
                            ? "d-flex align-items-center justify-content-center"
                            : ""
                    }`}
                >
                    <Button
                        label="app.notification.show_all"
                        labelParams={{total: count}}
                        block={false}
                        link
                        to="/notifications"
                        variant="outline"
                    />
                </div>
            </Card.Body>
        </Card>
    );
}
