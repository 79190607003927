import { Fab, Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useTranslate } from "ra-core";
import * as React from "react";
import { FC, memo, ReactElement } from "react";
import { Link } from "react-router-dom";
import Button, { ButtonProps, sanitizeButtonRestProps } from "..";

const BCreateButton: FC<CreateButtonProps> = (props) => {
  const {
    basePath = "",
    className,
    classes: classesOverride,
    label = "ra.action.create",
    icon = defaultIcon,
    variant,
    ...rest
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return isSmall ? (
    <Fab
      component={Link}
      color="primary"
      className={classnames(classes.floating, className)}
      to={`${basePath}/create`}
      aria-label={label && translate(label)}
      {...sanitizeButtonRestProps(rest)}
    >
      {icon}
    </Fab>
  ) : (
    <Button
      link={!!!rest.onClick}
      to={`${basePath}/create`}
      className={className}
      label={label}
      variant={variant}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};

const CreateIcon = () => (
  <i className="fas fa-plus-circle mr-1" aria-hidden="true" />
);

const defaultIcon = <CreateIcon />;
// <ContentAdd />;

const useStyles = makeStyles(
  (theme) => ({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 60,
      left: "auto",
      position: "fixed",
      zIndex: 1000,
    },
    floatingLink: {
      color: "inherit",
    },
  }),
  { name: "RaCreateButton" }
);

interface Props {
  basePath?: string;
  icon?: ReactElement;
}

export type CreateButtonProps = Props & ButtonProps;

const CreateButton = memo(BCreateButton, (prevProps, nextProps) => {
  return (
    prevProps.basePath === nextProps.basePath &&
    prevProps.label === nextProps.label &&
    prevProps.translate === nextProps.translate &&
    prevProps.to === nextProps.to
  );
});

export default CreateButton;
