import EditButton from "@main-components/buttons/edit-button";
import DateField from "@main-components/fields/date-field";
import SelectField from "@main-components/fields/select-field";
import TextField from "@main-components/fields/text-field";
import ShowContainer from "@main-components/show-container";
import SimpleShowLayout from "@main-components/show-simple-layout";
import * as React from "react";
import { TopToolbar } from "react-admin";

const AdminShowActions = (props) => {
  return (
    <TopToolbar {...props}>
      <EditButton
        record={props.data}
        basePath="/accounts"
        //variant="outline"
        //className="mr-1"
      />
    </TopToolbar>
  );
};

const AdminShow = (props) => (
  <ShowContainer {...props} actions={<AdminShowActions />}>
    <SimpleShowLayout>
      <TextField source="firstName" label="general.first_name" />
      <TextField source="lastName" label="general.last_name" />
      <TextField source="email" label="general.email" />
      <SelectField
        label="general.role"
        source="role"
        choices={[
          { id: "SUPERADMIN", name: "Super Admin" },
          { id: "ADMIN", name: "Admin" },
          { id: "WAREHOUSE_KEEPER", name: "Warehouse keeper" },
        ]}
      />
      <DateField source="createdAt" showTime label="general.created_at" />
    </SimpleShowLayout>
  </ShowContainer>
);

export default AdminShow;
