import { apiUrl } from "@app";
import SimpleForm from "@main-components/forms/simple-form";
import CheckboxGroupInput from "@main-components/inputs/checkbox-group-input";
import TextInput from "@main-components/inputs/text-input";
import LocaleSwitcher from "@main-components/locale-switcher";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useState } from "react";
import {
  required,
  useAuthenticated,
  useEditController,
  useGetIdentity,
  useLocale,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin";
import { Card } from "react-bootstrap";

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
});

const SettingsContent = ({ id }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();
  const { loaded: permissionsLoaded, permissions } = usePermissions();

  const LanguageForm = () => {
    const { record, save, saving } = useEditController({
      id: id,
      resource: "settings",
      basePath: "/settings",
      undoable: false,
    });

    if (record && record.id) {
      delete record.id;
    }

    return (
      <SimpleForm
        save={(data) => {
          save(data);
        }}
        record={record}
        saving={saving}
        undoable={false}
        toolbar={<div />}
        initialValues={{ language: locale }}
      >
        <LocaleSwitcher />
      </SimpleForm>
    );
  };

  const NotificationsForm = () => {
    const { record, save, saving } = useEditController({
      id: id,
      resource: "settings",
      basePath: "/settings",
      undoable: false,
    });

    if (record && record.id) {
      delete record.id;
    }

    return (
      <SimpleForm
        save={(data) => {
          save(data);
        }}
        record={record}
        saving={saving}
        undoable={false}
      >
        <CheckboxGroupInput
          label={false}
          source="notifications"
          choices={[
            { id: "notificationNewOrder", name: "settings.new_order" },
            {
              id: "notificationNewRealization",
              name: "settings.new_realization",
            },
            {
              id: "notificationNewPasswordChangeRequest",
              name: "settings.new_password_change_request",
            },
            {
              id: "notificationIncompleteOrderCompleted",
              name: "settings.incomplete_order_completed",
            },
          ]}
          translateChoices={true}
        />
      </SimpleForm>
    );
  };

  const NotificationsWarehouseForm = () => {
    const { record, save, saving } = useEditController({
      id: id,
      resource: "settings",
      basePath: "/settings",
      undoable: false,
    });

    if (record && record.id) {
      delete record.id;
    }

    return (
      <SimpleForm
        save={(data) => {
          save(data);
        }}
        record={record}
        saving={saving}
        undoable={false}
      >
        <CheckboxGroupInput
          label={false}
          source="notifications"
          choices={[
            { id: "notificationNewOrder", name: "settings.new_order" },

            {
              id: "notificationIncompleteOrderCompleted",
              name: "settings.incomplete_order_completed",
            },
          ]}
          translateChoices={true}
        />
      </SimpleForm>
    );
  };

  const EmailsForm = () => {
    const { record, save, saving } = useEditController({
      id: id,
      resource: "settings",
      basePath: "/settings",
      undoable: false,
    });

    if (record && record.id) {
      delete record.id;
    }

    return (
      <SimpleForm
        save={(data) => {
          save(data);
        }}
        record={record}
        saving={saving}
        undoable={false}
      >
        <CheckboxGroupInput
          label={false}
          source="emails"
          choices={[
            { id: "emailNewOrder", name: "settings.new_order" },
            {
              id: "emailNewRealization",
              name: "settings.new_realization",
            },
            {
              id: "emailNewPasswordChangeRequest",
              name: "settings.new_password_change_request",
            },
            {
              id: "emailIncompleteOrderCompleted",
              name: "settings.incomplete_order_completed",
            },
          ]}
          translateChoices={true}
        />
      </SimpleForm>
    );
  };

  const EmailsWarehouseForm = () => {
    const { record, save, saving } = useEditController({
      id: id,
      resource: "settings",
      basePath: "/settings",
      undoable: false,
    });

    if (record && record.id) {
      delete record.id;
    }

    return (
      <SimpleForm
        save={(data) => {
          save(data);
        }}
        record={record}
        saving={saving}
        undoable={false}
      >
        <CheckboxGroupInput
          label={false}
          source="emails"
          choices={[
            { id: "emailNewOrder", name: "settings.new_order" },
            {
              id: "emailIncompleteOrderCompleted",
              name: "settings.incomplete_order_completed",
            },
          ]}
          translateChoices={true}
        />
      </SimpleForm>
    );
  };
  const ChangePasswordForm = ({ id }) => {
    const [savingPassword, setSavingPassword] = useState(false);
    const notify = useNotify();
    const changePassword = (data) => {
      let authToken = localStorage.getItem("storevan_admin_token");

      setSavingPassword(true);
      fetch(`${apiUrl}/change-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      })
        .then(async (res) => {
          const r = await res.json();

          if (r.ok) {
            /* @ts-ignore */
            notify("settings.password_changed", "success");
          } else {
            notify("settings.invalid_password", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          notify("Error");
        })
        .finally(() => {
          setSavingPassword(false);
        });
    };

    return (
      <SimpleForm
        save={changePassword}
        saving={savingPassword}
        undoable={false}
        validate={(values) => {
          let errors: { newPasswordConfirm: string | undefined } = {
            newPasswordConfirm: undefined,
          };

          if (
            values.newPassword &&
            values.newPasswordConfirm &&
            values.newPassword !== values.newPasswordConfirm
          ) {
            errors.newPasswordConfirm = "settings.reset_password_mismatch";
          }
          return errors;
        }}
      >
        <TextInput
          source="currentPassword"
          label="settings.current_password"
          validate={required()}
          type="password"
        />
        <TextInput
          source="newPassword"
          label="settings.new_password"
          validate={required()}
          type="password"
        />
        <TextInput
          source="newPasswordConfirm"
          label="settings.new_password_confirm"
          validate={required()}
          type="password"
        />
      </SimpleForm>
    );
  };

  if (!permissionsLoaded) return null;

  const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1>{translate("general.configuration")}</h1>
      </div>

      <div className="row" data-masonry='{"percentPosition": true }'>
        <div className="col-xs-12 col-md-6 col-xl-6 mb-4">
          <Card className="mb-4">
            <Card.Header>
              <i className="far fa-cog mr-2"></i>{" "}
              {translate("settings.titles.general_settings")}
            </Card.Header>
            <Card.Body>
              <LanguageForm />
            </Card.Body>
          </Card>

          {isAdmin ? (
            <Card>
              <Card.Header>
                <i className="far fa-bell-exclamation mr-2"></i>{" "}
                {translate("settings.send_notification_when")}
              </Card.Header>
              <Card.Body>
                <NotificationsForm />
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Header>
                <i className="far fa-bell-exclamation mr-2"></i>{" "}
                {translate("settings.send_notification_when")}
              </Card.Header>
              <Card.Body>
                <NotificationsWarehouseForm />
              </Card.Body>
            </Card>
          )}
        </div>
        <div className="col-xs-12 col-md-6 col-xl-6 mb-4">
          {isAdmin ? (
            <Card className="mb-4">
              <Card.Header>
                <i className="far fa-envelope mr-2"></i>{" "}
                {translate("settings.send_email_when")}
              </Card.Header>
              <Card.Body>
                <EmailsForm />
              </Card.Body>
            </Card>
          ) : (
            <Card className="mb-4">
              <Card.Header>
                <i className="far fa-envelope mr-2"></i>{" "}
                {translate("settings.send_email_when")}
              </Card.Header>
              <Card.Body>
                <EmailsWarehouseForm />
              </Card.Body>
            </Card>
          )}

          <Card className="mb-4">
            <Card.Header>
              <i className="far fa-user-unlock mr-2"></i>{" "}
              {translate("settings.change_password")}
            </Card.Header>
            <Card.Body>
              <ChangePasswordForm id={id} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const Settings = () => {
  useAuthenticated();

  const { loaded, loading, identity } = useGetIdentity();

  if (!loaded || !identity) return null;

  return <SettingsContent id={identity.id} />;
};

export default Settings;
