import AppBarTitle from "@main-components/app-bar-title";
import Card from "@main-components/card";
import EditIconButton from "@main-components/edit-icon-button";
import {DateField} from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {useOverviewState,} from "@main-components/list/utils/use-overview-context";
import DealerReferenceField from "@main/dealers/shared/dealer-reference-field";
import moment from "moment";
import * as React from "react";
import {
    FunctionField,
    LinearProgress,
    ReferenceField,
    useGetOne,
    usePermissions,
    useShowController,
    useTranslate,
} from "react-admin";
import {Table} from "react-bootstrap";
import {OrderEditModal} from "../edit";
import OrderStatusField, {orderStatus} from "../shared/status-field";
import TextUtils from "@misc/utils/text";
import Badge from "@main-components/badge";

const ShowLayout = (props) => {
    const {record, translate} = props;
    const {setEditActive, setEditContext, editContext} = useOverviewContext();
    const {loaded: permissionsLoaded, permissions} = usePermissions();

    const showDeliveryInfo =
        record.status === "IN_BACK_ORDER" ||
        record.status === "SCHEDULED" ||
        record.status === "SENT";

    const showAdminComments = record.status === "NEED_MORE_INFO";

    if (!permissionsLoaded) return null;

    const isAdmin = true; // permissions === "SUPERADMIN" || permissions === "ADMIN";
    const canEdit = record.status === "RECEIVED" || record.status === "NEED_MORE_INFO" || record.status === "IN_BACK_ORDER"


    function downloadFile(fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const ExtraProductRow = (props) => {
        if (!props.record) return null;

        return (
            <tr>
                <td className="align-middle">
                    <img
                        src={props.record.imageUrl}
                        style={{width: 100, height: 100}}
                    />
                </td>
                <td className="align-middle">
                    <small className="d-block text-muted">{props.record.code}</small>
                    <b>{props.record.name}</b>
                    <div className="mt-2 mb-2 d-flex align-items-xxl-center">
                        <small className="d-block mr-3">
                            {TextUtils.numberToPrice(props.record.sellingPrice)}
                        </small>

                        <FunctionField
                            source="status"
                            render={(record) => {
                                return record.inBackOrder ? (
                                    <Badge
                                        label={translate("resources.products.fields.inBackOrder")}
                                        color={"warning"}
                                    />
                                ) : (
                                    <Badge
                                        label={`${translate("resources.products.fields.inStock")}`}
                                        color={"success"}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="mt-2 mb-2">
                        <span className="form-label form-text">
                          {props.translate("general.notes")}
                        </span>
                        <small className="d-block">
                            {props.record.note ? props.record.note : props.translate("general.no")}
                        </small>
                    </div>
                </td>
                <td
                    width="20%"
                    className="align-middle"
                >
                    {props.quantity}{" "}
                    {props
                    .translate("general.piece", {smart_count: props.quantity})
                    .toLowerCase()}
                </td>
            </tr>
        );
    };

    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
                    <Card className="mb-4">
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.order_info")}
                            {isAdmin && <EditIconButton context="basic_info"/>}
                        </Card.Header>
                        <Card.Body>
                            <Table className="m-0">
                                <tbody>
                                <tr>
                                    <th scope="row">
                                        {translate("resources.orders.fields.orderId")}
                                    </th>
                                    <td>
                                        <TextField
                                            source="orderId"
                                            addLabel={false}
                                            record={record}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">
                                        {translate("resources.orders.fields.reference")}
                                    </th>
                                    <td>
                                        <TextField
                                            source="reference"
                                            addLabel={false}
                                            record={record}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">
                                        {translate("resources.orders.fields.dealer")}
                                    </th>
                                    <td>
                                        <DealerReferenceField
                                            addLabel={false}
                                            record={record}
                                            basePath="/dealers"
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">
                                        {translate("resources.orders.fields.date")}
                                    </th>
                                    <td>
                                        <DateField
                                            source="date"
                                            showTime
                                            addLabel={false}
                                            record={record}
                                        />
                                    </td>
                                </tr>

                                {record.desiredDeliveryDate && (
                                    <tr>
                                        <th scope="row">
                                            {translate("resources.orders.fields.desiredDeliveryDate")}
                                        </th>
                                        <td>
                                            <DateField
                                                source="desiredDeliveryDate"
                                                addLabel={false}
                                                record={record}
                                            />
                                        </td>
                                    </tr>
                                )}

                                {record.deliveryDate && showDeliveryInfo && (
                                    <tr>
                                        <th scope="row">
                                            {translate("resources.orders.fields.deliveryDate")}
                                        </th>
                                        <td>
                                            <DateField
                                                source="deliveryDate"
                                                addLabel={false}
                                                record={record}
                                            />
                                        </td>
                                    </tr>
                                )}


                                {showDeliveryInfo && record.soNumber && (
                                    <tr>
                                        <th scope="row">
                                            {translate("resources.orders.fields.soNumber")}
                                        </th>
                                        <td>
                                            <TextField
                                                source="soNumber"
                                                addLabel={false}
                                                record={record}
                                            />
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <th scope="row">
                                        {translate("resources.orders.fields.status")}
                                    </th>
                                    <td>
                                        <OrderStatusField
                                            record={record}
                                            addLabel={false}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4">
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.pick_lists")}
                            {canEdit && isAdmin && <EditIconButton context="pick_lists"/>}
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                {record.productsFiles && record.productsFiles.length > 0
                                    ? record.productsFiles.map((item) => (
                                        <tbody>
                                        <tr>
                                            <td>
                                                <TextField
                                                    source="url"
                                                    record={{
                                                        id: record.id,
                                                        url: item.url.substr(
                                                            item.url.lastIndexOf("/") + 1,
                                                            item.url.length - 1
                                                        ),
                                                    }}
                                                    addLabel={false}
                                                />
                                            </td>
                                            <td width="30%">
                                                <i className="fal fa-download"></i>{" "}

                                                <a
                                                    href={"#"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        downloadFile(item.url)
                                                    }}
                                                    title={translate("general.download_file")}
                                                >
                                                    {translate("general.download")}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    ))
                                    : translate("general.no")}
                            </Table>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.mockups")}
                            {canEdit && isAdmin && <EditIconButton context="mockups"/>}
                        </Card.Header>
                        <Card.Body>
                            {record.attachments && record.attachments.length > 0
                                ? record.attachments.map((item) => (
                                    <Table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <TextField
                                                    source="url"
                                                    addLabel={false}
                                                    record={{
                                                        ...item,
                                                        url: item.url.substr(
                                                            item.url.lastIndexOf("/") + 1,
                                                            item.url.length - 1
                                                        ),
                                                    }}
                                                />
                                            </td>
                                            <td width="30%">
                                                <i className="fal fa-download"></i>{" "}
                                                <a
                                                    href={"#"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        downloadFile(item.url)
                                                    }}
                                                    title={translate("general.download_file")}
                                                >
                                                    {translate("general.download")}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                ))
                                : translate("general.no")}
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
                    <Card className="mb-4">
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.products")}
                            {canEdit && isAdmin && <EditIconButton context="additional_products"/>}
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                {record.items && record.items.length > 0 ? (
                                    <tbody>
                                    {record.items.map((item) => {
                                        return (
                                            <ReferenceField
                                                reference="products"
                                                record={item}
                                                source="productId"
                                                basePath="/products"
                                                addLabel={false}
                                                link={false}
                                            >
                                                <ExtraProductRow
                                                    quantity={item.quantity}
                                                    note={item.note}
                                                    translate={translate}
                                                />
                                            </ReferenceField>
                                        );
                                    })}
                                    </tbody>
                                ) : (
                                    translate("general.no")
                                )}
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="mb-4">
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.additional_comments")}
                            {canEdit && isAdmin && <EditIconButton context="comments"/>}
                        </Card.Header>
                        <Card.Body>
                            <TextField
                                source="extraComments"
                                addLabel={false}
                                record={record}
                                emptyText={translate("general.no")}
                            />
                        </Card.Body>
                    </Card>
                    {record.adminComments && showAdminComments && (
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                {translate("resources.orders.titles.observations")}
                            </Card.Header>
                            <Card.Body>
                                <TextField
                                    source="adminComments"
                                    addLabel={false}
                                    record={record}
                                />
                            </Card.Body>
                        </Card>
                    )}
                    <Card className="mb-4">
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.delivery_notes")}
                        </Card.Header>
                        <Card.Body>
                            {record.deliveryNotes && record.deliveryNotes.length > 0
                                ? record.deliveryNotes.map((item) => (
                                    <Table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <TextField
                                                    source="name"
                                                    addLabel={false}
                                                    record={{
                                                        ...item,
                                                        name: moment(item.createdAt).format("DD/MM/Y HH:mm"),
                                                    }}
                                                />
                                            </td>
                                            <td width="30%">
                                                <i className="fal fa-download"></i>{" "}
                                                <a
                                                    href={item.url}
                                                    target="_BLANK"
                                                    title={translate("general.download_file")}
                                                >
                                                    {translate("general.download")}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                ))
                                : translate("general.no")}
                        </Card.Body>
                    </Card>
                </div>
                <div className="col col-xl-4 mb-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            {translate("resources.orders.titles.order_history")}
                        </Card.Header>
                        <Card.Body>
                            <Timeline {...props} />
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <OrderEditModal
                resource="orders"
                basePath="/orders"
                id={record.id}
            />
        </>
    );
};

const Timeline = (props) => {
    const {loading, loaded, data} = useGetOne("order-history", props.record.id);
    const translate = useTranslate();

    if (!loaded || !data) {
        return <LinearProgress/>;
    }

    return (
        <ul className="timeline">
            {data.events.map((e) => {
                return (
                    <li>
                        <small className="text-muted float-right d-inline-block align-middle">
                            {moment(e.date).format("DD/MM/Y")} om{" "}
                            {moment(e.date).format("HHumm")}
                        </small>
                        <p>{translate(`resources.orders.history.${e.title}`)}</p>
                    </li>
                );
            })}
        </ul>
    );
};

const OrderShow = (props) => {
    const {record} = useShowController(props);
    const translate = useTranslate();
    const overviewInitialState = {...useOverviewState()};
    if (!record) return null;

    const renderProgress = () => {

        const index = orderStatus.map((s) => s.id).indexOf(record.status);

        return (
            <Card className="mb-4 px-5">
                <Card.Body className="p-5">
                    <div className="position-relative m-4">
                        <div
                            className="progress"
                            style={{height: 1}}
                        >
                            <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{width: index === 0 ? "0%" : index <= 4 ? "50%" : "100%"}}
                            ></div>
                        </div>
                        <div className="position-absolute top-0 left-0">
              <span
                  className="translate-middle bg-primary text-white rounded-pill d-inline-block text-center align-middle"
                  style={{width: "2rem", height: "2rem", lineHeight: "2rem"}}
              >
                1
              </span>
                            <span className="translate-middle d-block text-center align-middle">
                {translate("resources.orders.status.order_placed")}
              </span>
                        </div>
                        <div className="position-absolute top-0 left-50">
              <span
                  className={`translate-middle ${
                      index <= 5 && index > 0 ? "bg-primary" : "bg-secondary"
                  } text-white rounded-pill d-inline-block text-center align-middle`}
                  style={{width: "2rem", height: "2rem", lineHeight: "2rem"}}
              >
                2
              </span>
                            <span className="translate-middle d-block text-center align-middle">
                {translate("resources.orders.status.being_processed")}
              </span>
                        </div>
                        <div className="position-absolute top-0 left-100">
              <span
                  className={`translate-middle ${
                      index >= 5 ? "bg-primary" : "bg-secondary"
                  }  text-white rounded-pill d-inline-block text-center align-middle`}
                  style={{width: "2rem", height: "2rem", lineHeight: "2rem"}}
              >
                3
              </span>
                            <span className="translate-middle d-block text-center align-middle">
                {translate("resources.orders.status.sent")}
              </span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    const renderTitle = () => {
        return (
            <>
                <AppBarTitle title={record.reference}/>

                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
                    <h1 id="react-admin-title">
                        <small className="h4 text-muted order-2 ml-2">
                            #{record.orderId}
                        </small>
                    </h1>{" "}
                </div>
            </>
        );
    };

    return (
        <OverviewContext.Provider value={{...overviewInitialState}}>
            <div>
                {renderTitle()}
                {renderProgress()}
                <ShowLayout
                    translate={translate}
                    record={record}
                />
            </div>
        </OverviewContext.Provider>
    );
};
export default OrderShow;
