import EditButton from "@main-components/buttons/edit-button";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import ShowContainer from "@main-components/show-container";
import SimpleShowLayout from "@main-components/show-simple-layout";
import * as React from "react";
import { TopToolbar } from "react-admin";

const WarehouseKeeperShowActions = (props) => {
  return (
    <TopToolbar {...props}>
      <EditButton
        record={props.data}
        basePath="/warehouse-keepers"
        //variant="outline"
        //className="mr-1"
      />
    </TopToolbar>
  );
};

const WarehouseKeeperShow = (props) => (
  <ShowContainer {...props} actions={<WarehouseKeeperShowActions />}>
    <SimpleShowLayout>
      <TextField source="firstName" label="general.first_name" />
      <TextField source="lastName" label="general.last_name" />
      <TextField source="email" label="general.email" />

      <DateField source="createdAt" showTime label="general.created_at" />
    </SimpleShowLayout>
  </ShowContainer>
);

export default WarehouseKeeperShow;
