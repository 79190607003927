import DataGrid from "@main-components/data-grid";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {
    Filter,
    FunctionField,
    ReferenceInput,
    required,
    useGetIdentity,
    useGetList,
    useGetOne,
    usePermissions,
    useTranslate,
    useUpdate
} from "react-admin";
import {useOverviewState} from "@main-components/list/utils/use-overview-context";
import OverviewContext from "@main-components/list/utils/overview-context";
import {makeStyles} from "@material-ui/core/styles";
import SyncWebshopButton from "@main/products/sync/components/sync-webshop-button";
import SelectInput from "@main-components/inputs/select-input";
import SimpleForm from "@main-components/forms/simple-form";
import {Typography} from "@material-ui/core";
import DateTimeUtils from "@misc/utils/date-time-utils";
import BooleanInput from "@main-components/inputs/boolean-input";

const he = require('he');


const MainActions = (props) => {
    const translate = useTranslate();

    const {identity} = useGetIdentity();
    const company = useGetOne("company-info", identity?.id as any, {enabled: !!identity})


    return (
        <div>
            <Typography
                variant="caption"
                color="inherit"
                style={{
                    marginRight: 20
                }}
            >
                {translate("general.last_synced_at")}: {company?.data?.lastWebshopSyncAt && DateTimeUtils.ago(new Date(parseInt(company?.data?.lastWebshopSyncAt)))}
            </Typography>

            <SyncWebshopButton {...props} />
        </div>
    );
};

const useStyles = makeStyles({
    centered: {
        textAlign: 'center',
    },
});


const ListContent = (props) => {
    const {count} = useResourceCount("webshop-products", {source: "exact", deleted: false});
    const translate = useTranslate();
    const {loaded: permissionsLoaded, permissions} = usePermissions();
    const classes = useStyles();

    const subHeader =
        count > 0
            ? `${count} ${translate("resources.products.name", {
                smart_count: count === 1 ? 1 : 2,
            })}`
            : "";

    if (!permissionsLoaded) return null;

    const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";


    const ListFilters = (props) => (
        <Filter {...props}>
            <TextInput
                source="q"
                alwaysOn
                label={false}
                placeholder={translate("general.search")}
                InputProps={{
                    startAdornment: (
                        <span
                            className="input-group-text h-100"
                            id="product-search-addon"
                        >
                          <i
                              className="fal fa-search"
                              aria-hidden="true"
                          />
                        </span>
                    ),
                }}
            />

            <ReferenceInput
                reference={"webshop-product-categories"}
                source={"webshopCategoryId"}
                label={"resources.webshop-products.fields.category"}
            >
                <CategoryFilterInput/>
            </ReferenceInput>
        </Filter>
    );

    return (
        <>
            <List
                {...props}
                listSubHeader={subHeader}
                title={props.options.label}
                bulkActionButtons={false}
                exporter={false}
                mainActions={isAdmin ? <MainActions/> : <div></div>}
                component={ListWrapper}
                filters={<ListFilters/>}
                filter={{
                    source: "exact",
                    deleted: false
                }}
                perPage={100}
            >
                <DataGrid rowClick={undefined}>

                    <FunctionField
                        source="imageUrl"
                        render={(record) => {
                            return <img
                                src={record.imageUrl}
                                style={{width: 100, height: 100}}
                                alt=""
                            />
                        }}
                    />

                    <TextField source="code"/>
                    <TextField source="name"/>

                    <EditWebShopProductCategoryInput label={"resources.webshop-products.fields.category"}/>

                    {/*  <WebShopProductSwitchInput
                        label={"resources.webshop-products.fields.visible"}
                    />*/}
                    <WebShopProductFeaturedInput
                        label={"resources.webshop-products.fields.featuredInWebshop"}
                    />
                </DataGrid>
            </List>
        </>

    );
};

function CategoryFilterInput(props) {
    return (
        <SelectInput
            {...props}
            choices={props.choices.map((cat) => ({
                id: cat.id,
                name: he.decode(cat.name)
            }))}
            margin={"none"}
            style={{
                margin: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 250,
                minWidth: 200
            }}
        />
    )
}

function EditWebShopProductCategoryInput(props) {

    const {data, ids} = useGetList("webshop-product-categories")
    const categories = ids?.map((id) => data[id]) ?? []

    const [update] = useUpdate(props.resource, props.record.id, undefined, props.record, {
        mutationMode: "pessimistic",
    })

    return (
        <SimpleForm
            record={props.record}
            resource={props.resource}
            toolbar={<div></div>}
        >
            <SelectInput
                margin={"none"}
                label={false}
                source={`webshopCategoryId`}
                validate={[required()]}
                choices={categories.map((cat) => ({
                    id: cat.id,
                    name: he.decode(cat.name)
                }))}

                style={{
                    margin: 0,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: 250,
                    minWidth: 200
                }}

                onChange={(e) => {
                    update(props.resource, props.record.id, {
                        webshopCategoryId: e.currentTarget.value
                    })
                }}
            />
        </SimpleForm>
    )
}

function WebShopProductSwitchInput(props) {

    const [update] = useUpdate(props.resource, props.record.id, undefined, props.record, {
        mutationMode: "pessimistic",
    })

    return (

        <SimpleForm
            record={props.record}
            resource={props.resource}
            toolbar={<div></div>}
        >
            <BooleanInput
                margin={"none"}
                label={false}
                source={"visibleInWebshop"}
                style={{
                    width: "fit-content",
                    margin: 0,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
                onChange={(value) => {
                    update(props.resource, props.record.id, {
                        visibleInWebshop: value
                    })
                }}
            />
        </SimpleForm>
    )
}

function WebShopProductFeaturedInput(props) {

    const [update] = useUpdate(props.resource, props.record.id, undefined, props.record, {
        mutationMode: "pessimistic",
    })

    return (

        <SimpleForm
            record={props.record}
            resource={props.resource}
            toolbar={<div></div>}
        >
            <BooleanInput
                margin={"none"}
                label={false}
                source={"featuredInWebshop"}
                style={{
                    width: "fit-content",
                    margin: 0,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
                onChange={(value) => {
                    update(props.resource, props.record.id, {
                        featuredInWebshop: value
                    })
                }}
            />
        </SimpleForm>
    )
}


const WebShopProductList = (props) => {
    return (
        <OverviewContext.Provider value={{...useOverviewState()}}>
            <ListContent {...props} />
        </OverviewContext.Provider>
    );
};
export default WebShopProductList;
