import Button from "@main-components/buttons";
import ImageField from "@main-components/fields/image-field";
import EditFormContainer from "@main-components/forms/edit-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ArrayInput from "@main-components/inputs/array-input";
import BooleanInput from "@main-components/inputs/boolean-input";
import ImageInput from "@main-components/inputs/image-input";
import RichTextInput from "@main-components/inputs/rich-text-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import { useArrayInputStyles } from "@main/orders/create";
import * as React from "react";
import { required, useNotify, useRefresh, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";
import { transformNewsData } from "../create";

const NewsEditForm = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const arrayInputClasses = useArrayInputStyles();

  return (
    <EditFormContainer
      {...props}
      id={props.id}
      component={"div"}
      onSuccess={(record) => {
        props.handleClose();
        refresh();
        /* @ts-ignore */
        notify("resources.news.notifications.created", "success");
      }}
      undoable={false}
      transform={transformNewsData}
    >
      <SimpleForm redirect="show">
        <TextInput source="title" validate={[required()]} />
        <RichTextInput
          validate={[required()]}
          source="content"
          toolbar={[
            ["bold", "italic", "underline", "link"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ font: [] }],
          ]}
        />
        <ImageInput source="featuredImage" accept="image/jpeg,image/png">
          <ImageField source="src" />
        </ImageInput>
        <BooleanInput source="pinned" />
        <ArrayInput source="gallery">
          <SimpleFormIterator
            addButton={
              <Button
                label="resources.news.actions.add_extra_image"
                variant="outline"
              />
            }
            removeButton={
              <Button
                label="ra.action.delete"
                variant="link"
                color="danger"
                style={{ color: "red" }}
              />
            }
            /* @ts-ignore */
            classes={{
              line: arrayInputClasses.line,
            }}
          >
            <ImageInput
              source="url"
              label={false}
              accept="image/jpeg,image/png"
            >
              <ImageField source="src" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </EditFormContainer>
  );
};

const NewsEditModal = ({ resource, basePath, id }) => {
  const { setEditActive, editContext, editActive, setEditContext } =
    useOverviewContext();

  const handleClose = () => {
    setEditActive(false);
    setEditContext(undefined);
  };

  const translate = useTranslate();

  return (
    <Modal
      show={editActive && editContext === "details"}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.news.titles.edit_post")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <NewsEditForm
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
          id={id}
        />
      </Modal.Body>
    </Modal>
  );
};

export default NewsEditModal;
