import DateField from "@main-components/fields/date-field";
import ShowContainer from "@main-components/show-container";
import SimpleShowLayout from "@main-components/show-simple-layout";
import * as React from "react";
import { useShowController } from "react-admin";
import EventReferenceField from "../shared/event-reference-field";
import ResourceReferenceField from "../shared/resource-reference-field";
import ResourceTypeReferenceField from "../shared/resource-type-reference-field";

const LogShow = (props) => {
  const { record } = useShowController(props);

  return (
    <ShowContainer {...props}>
      <SimpleShowLayout>
        <ResourceTypeReferenceField />
        <EventReferenceField />
        <ResourceReferenceField />
        <DateField source="occurredOn" showTime />
      </SimpleShowLayout>
    </ShowContainer>
  );
};

export default LogShow;
