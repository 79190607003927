import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { required, useNotify, useRedirect, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";

export const transformDownloadCategoryData = (data) => {
  if (data.pictureUrl && data.pictureUrl.rawFile) {
    data.pictureUrl = data.pictureUrl.rawFile;
  }

  return data;
};

const DownloadTypeCreateFrom = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <CreateFormContainer
      {...props}
      component={"div"}
      transform={transformDownloadCategoryData}
      onSuccess={(newRecord) => {
        props.handleClose();
        redirect("show", "/downloadCategory", newRecord.data.id);
        /* @ts-ignore */
        notify("resources.downloadCategory.notifications.created", "success");
      }}
    >
      <SimpleForm redirect="list">
        <TextInput
          source="name"
          label="resources.downloadCategory.fields.name"
          validate={[required()]}
        />

        <TextInput
          source="description"
          label="resources.downloadCategory.fields.description"
          as="textarea"
        />

        <SimpleFileInput
          source="pictureUrl"
          label="general.file"
          InputProps={{
            placeholder: "resources.downloadCategory.messages.upload_image",
          }}
        />
      </SimpleForm>
    </CreateFormContainer>
  );
};

const DownloadCategoryCreateModal = ({ resource, basePath }) => {
  const { createActive, setCreateActive } = useOverviewContext();

  const handleClose = () => {
    setCreateActive(false);
  };
  const translate = useTranslate();

  return (
    <Modal show={createActive} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.downloadCategory.titles.create_category")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <DownloadTypeCreateFrom
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DownloadCategoryCreateModal;
