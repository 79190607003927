import ImageField from "@main-components/fields/image-field";
import EditFormContainer from "@main-components/forms/edit-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import FileInput from "@main-components/inputs/file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { required, useNotify, useRefresh, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";
import { transformDownloadData } from "../create";

const DownloadEditForm = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <EditFormContainer
      {...props}
      id={props.id}
      undoable={false}
      transform={(values) => transformDownloadData(values, props.categoryId)}
      component={"div"}
      onSuccess={(newRecord) => {
        props.handleClose();
        refresh();
        /* @ts-ignore */
        notify("resources.downloads.notifications.edited", "success");
      }}
    >
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput multiline source="description" />

        {/*  <SimpleFileInput
          source="file"
          label="general.file"
          validate={[required()]}
          InputProps={{
            placeholder: "general.select_file",
          }}
        /> */}

        <FileInput
          source="file"
          label="general.file"
          validate={[required()]}
          InputProps={{
            placeholder: "general.select_file",
          }}
        >
          <Preview />
        </FileInput>
      </SimpleForm>
    </EditFormContainer>
  );
};

function Preview(props) {
  const isImage = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"].includes(
    props.record.src.split(".").pop()
  );
  return isImage ? (
    <ImageField {...props} source="src" />
  ) : (
    <a href={props.record.src} target="_blank">
      {props.record.src}
    </a>
  );
}
const DownloadEditModal = ({ resource, basePath, id, categoryId }) => {
  const { setEditActive, editContext, editActive, setEditContext } =
    useOverviewContext();

  const handleClose = () => {
    setEditActive(false);
    setEditContext(undefined);
  };
  const translate = useTranslate();

  return (
    <Modal
      show={editActive && editContext === "edit_download"}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.downloads.titles.edit_download")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <DownloadEditForm
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
          id={id}
          categoryId={categoryId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DownloadEditModal;
