import {apiUrl} from "@app";
import Button from "@main-components/buttons";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import RichTextField from "@main-components/fields/rich-text-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import {useNotificationReset} from "@main-components/use-notification-count";
import * as React from "react";
import {TopToolbar, useGetIdentity, useNotify} from "react-admin";
import {Card} from "react-bootstrap";

const token = localStorage.getItem("storevan_admin_token");

const markAllAsSeenRequest = (adminId) => {
    return fetch(`${apiUrl}/notifications/mark-all-as-seen`, {
        method: "POST",
        body: JSON.stringify({adminId: adminId}),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
};

const ListActions = (props) => {
    const notify = useNotify();
    const {identity} = useGetIdentity();

    const {deleteNotifications} = useNotificationReset();

    return (
        <TopToolbar className="m-0 p-0 pt-1">
            <Button
                variant="contained"
                label="app.notification.mark_all_as_seen"
                onClick={() => {
                    deleteNotifications(() => {
                        notify("app.notification.notifications_deleted");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    });
                }}
            />
        </TopToolbar>
    );
};

const NotificationList = (props) => {
    const {loading, identity} = useGetIdentity();

    return (
        <List
            {...props}
            title={props.options.label}
            sort={{field: "createdAt", order: "desc"}}
            bulkActionButtons={false}
            exporter={false}
            component={ListWrapper}
            hasCreate={true}
            actions={<ListActions/>}
            empty={
                <Card
                    className="w-100"
                    style={{height: 500}}
                >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                        <Empty
                            hideActions
                            resourceOverride="notifications"
                            basePath="/notifications"
                            subtitle="app.notification.empty_message"
                            icon={
                                <div>
                                    <img
                                        src="/img/illustrations/notifications.svg"
                                        className="w-100"
                                    />
                                </div>
                            }
                        />
                    </Card.Body>
                </Card>
            }
        >
            <DataGrid>
                <DateField
                    source="createdAt"
                    showTime
                    label="general.created_at"
                />
                <TextField source="title"/>
                <RichTextField source="content"/>
            </DataGrid>
        </List>
    );
};
export default NotificationList;
