import Button from "@main-components/buttons";
import DeleteButton from "@main-components/buttons/delete-button";
import ImageField from "@main-components/fields/image-field";
import EditFormContainer from "@main-components/forms/edit-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import ArrayInput from "@main-components/inputs/array-input";
import ImageInput from "@main-components/inputs/image-input";
import TextInput from "@main-components/inputs/text-input";
import * as React from "react";
import {required, TopToolbar, useEditController, useNotify, useRefresh, useTranslate,} from "react-admin";
import {Card} from "react-bootstrap";
import {transformDealerData} from "../create";
import AcceptRequestPasswordChangeButton from "../shared/accept-password-change-button";
import AltContactFormIterator from "./alt_contact-form-iterator";

const TradeInfoForm = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const editController = useEditController({
        ...props,
        onSuccess: () => {
            notify("ra.notification.updated", "info", {smart_count: 1});
            refresh();
        },
        transform: transformDealerData,
    });

    if (!editController.record) return null;

    return (
        <SimpleForm {...editController}>
            <ImageInput
                source="logoUrl"
                accept="image/jpeg,image/png"
            >
                <ImageField source="src"/>
            </ImageInput>
            <TextInput
                source="brandName"
                validate={[required()]}
                helperText="resources.dealers.messages.brand_name"
            />
            <TextInput
                source="businessAddress"
                //validate={[required()]}
                helperText="resources.dealers.messages.business_address"
            />
        </SimpleForm>
    );
};

const CompanyInfoForm = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const editController = useEditController({
        ...props,
        onSuccess: () => {
            notify("ra.notification.updated", "info", {smart_count: 1});

            refresh();
        },
        transform: transformDealerData,
    });

    return (
        <SimpleForm {...editController}>
            <TextInput source="vatNumber"/>
            <TextInput
                source="companyName"
                helperText="resources.dealers.messages.company_name"
            />
            <TextInput
                source="headOfficeAddress"
                //validate={[required()]}
                helperText="resources.dealers.messages.head_office_address"
            />
        </SimpleForm>
    );
};

const ResponsibleInfo = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const editController = useEditController({
        ...props,
        onSuccess: () => {
            notify("ra.notification.updated", "info", {smart_count: 1});
            refresh();
        },
        transform: transformDealerData,
    });

    if (!editController.record) return null;

    return (
        <SimpleForm {...editController}>
            <ImageInput
                source="mainContact.profilePictureUrl"
                label="general.profile_picture"
                accept="image/jpeg,image/png"
            >
                <ImageField source="src"/>
            </ImageInput>

            <TextInput
                source="mainContact.firstName"
                label="general.first_name"
                validate={[required()]}
                helperText="resources.dealers.messages.main_contact_first_name"
            />
            <TextInput
                source="mainContact.lastName"
                label="general.last_name"
                validate={[required()]}
                helperText="resources.dealers.messages.main_contact_last_name"
            />

            <TextInput
                source="mainContact.email"
                label="general.email"
                validate={[required()]}
                helperText="resources.dealers.messages.main_contact_email"
            />

            <TextInput
                source="mainContact.companyRole"
                label="resources.dealers.fields.companyRole"
                helperText="resources.dealers.messages.main_contact_role"
                //  validate={[required()]}
            />
            <TextInput
                source="mainContact.phoneNumber"
                label="general.phone"
                //  validate={[required()]}
                helperText="resources.dealers.messages.main_contact_phone"
            />
        </SimpleForm>
    );
};

const AlternativeContactForm = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    const editController = useEditController({
        ...props,
        onSuccess: () => {
            notify("ra.notification.updated", "info", {smart_count: 1});
            refresh();
        },
        transform: transformDealerData,
    });

    return (
        <SimpleForm
            {...editController}
            /*  save={(values) => {
              console.log(values);
            }} */
        >
            <ArrayInput
                source="alternativeContacts"
                label={false}
            >
                <AltContactFormIterator
                    className="row"
                    removeButton={
                        <Button
                            label="ra.action.remove"
                            variant="outline"
                            color="danger"
                        />
                    }
                >
                    <ImageInput
                        source="profilePictureUrl"
                        label="general.profile_picture"
                        accept="image/jpeg,image/png"
                    >
                        <ImageField source="src"/>
                    </ImageInput>

                    <TextInput
                        source="email"
                        label="general.email"
                        validate={[required()]}
                    />
                    <TextInput
                        source="firstName"
                        label="general.first_name"
                        validate={[required()]}
                    />
                    <TextInput
                        source="lastName"
                        label="general.last_name"
                        validate={[required()]}
                    />
                    <TextInput
                        source="companyRole"
                        label="resources.dealers.fields.companyRole"
                        //validate={[required()]}
                        helperText="resources.dealers.messages.alt_contact_role"
                    />
                    <TextInput
                        source="phoneNumber"
                        label="general.phone"
                        // validate={[required()]}
                    />
                </AltContactFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
};

const Profile = (props) => {
    const translate = useTranslate();
    const [activeTab, setActiveTab] = React.useState(0);
    const {record} = props;
    if (!record) return null;

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
                <h1>{translate("resources.dealers.name", {smart_count: 1})}</h1>

                <TopToolbar {...props} style={{padding: 0, alignItems: "center"}}>
                    {record && record.passwordResetRequestedAt && (
                        <AcceptRequestPasswordChangeButton record={record}/>
                    )}
                    <DeleteButton
                        basePath="/dealers"
                        resource="dealers"
                        record={record}
                        variant="outline"
                        undoable={false}
                    />
                </TopToolbar>
            </div>
            <div className="row">
                <div className="col-auto mb-4">
                    <form>
                        <label className="form-label">
                            {translate("resources.dealers.fields.identificationId")}
                        </label>
                        <div className="input-group">
              <span
                  className="input-group-text"
                  id="dealerID-addon"
              >
                SVVD-ID
              </span>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="0105"
                                aria-describedby="dealerIDHelp"
                                disabled
                                value={record.identificationId}
                            />
                        </div>
                    </form>
                </div>
                <div className="col-auto mb-4">
                    <form>
                        <label className="form-label">
                            Exact ID
                        </label>
                        <div className="input-group">
              <span
                  className="input-group-text"
                  id="dealerID-addon"
              >
                EXACT-ID
              </span>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="0105"
                                aria-describedby="dealerIDHelp"
                                disabled
                                value={record.exactId}
                            />
                        </div>
                    </form>
                </div>
            </div>

            <nav>
                <div
                    className="nav nav-tabs border-bottom-0"
                    id="nav-tab"
                    role="tablist"
                >
                    <a
                        className={`nav-link ${activeTab === 0 ? "active" : ""}`}
                        id="nav-general-tab"
                        data-toggle="tab"
                        href="#nav-general"
                        role="tab"
                        aria-controls="nav-general"
                        aria-selected="true"
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveTab(0);
                        }}
                    >
                        {translate("resources.dealers.sections.general")}
                    </a>
                    <a
                        className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                        id="nav-contacts-tab"
                        data-toggle="tab"
                        href="#nav-contacts"
                        role="tab"
                        aria-controls="nav-contacts"
                        aria-selected="false"
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveTab(1);
                        }}
                    >
                        {translate("resources.dealers.sections.additional_contacts")}
                    </a>
                </div>
            </nav>

            <div
                className="tab-content p-4 border"
                id="nav-tabContent"
            >
                <div
                    className={`tab-pane fade  ${activeTab === 0 ? "show active" : ""}`}
                    id="nav-general"
                    role="tabpanel"
                    aria-labelledby="nav-general-tab"
                >
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
                            <Card>
                                <Card.Header>
                                    {translate("resources.dealers.titles.trade_data")}
                                </Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    <TradeInfoForm {...props} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
                            <Card>
                                <Card.Header>
                                    {translate("resources.dealers.titles.official_company_info")}
                                </Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    <CompanyInfoForm {...props} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
                            <Card>
                                <Card.Header>
                                    {translate(
                                        "resources.dealers.titles.responsible_contact_details"
                                    )}
                                </Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    <ResponsibleInfo {...props} />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                <div
                    className={`tab-pane fade  ${activeTab === 1 ? "show active" : ""}`}
                    id="nav-contacts"
                    role="tabpanel"
                    aria-labelledby="nav-contacts-tab"
                >
                    <AlternativeContactForm {...props} />
                </div>
            </div>
        </>
    );
};

const DealerShow = (props) => (
    <EditFormContainer
        {...props}
        actions={false}
        undoable={false}
        transform={transformDealerData}
        component={"div"}
    >
        <Profile {...props} />
    </EditFormContainer>
);

export default DealerShow;
