import {apiUrl} from "@app";
import AuthLayout from "@main-components/auth-layout";
import Button from "@main-components/buttons";
import SaveButton from "@main-components/buttons/save-button";
import SimpleForm from "@main-components/forms/simple-form";
import TextInput from "@main-components/inputs/text-input";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import {useState} from "react";
import {required, Toolbar, useNotify, useRedirect, useTranslate} from "react-admin";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxWidth: "330px",
        padding: "15px",
        margin: "auto",
        textAlign: "center",
    },
}));

const ForgotPassword = (props) => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const [saving, setSaving] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    const save = (data) => {
        setSaving(true);

        fetch(`${apiUrl}/request-reset-password`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then(async (res) => {
            const response = await res.json();

            if (res.status < 200 || res.status >= 300) {
                notify("ra.message.error", "warning");
            } else {
                if (response.msg) {
                    notify(`settings.${response.msg}`, "warning");
                } else {
                    notify("settings.password_change_request_sent");
                    redirect("/login");
                }
            }
        })
        .catch((e) => {
            notify("ra.message.error", "warning");
        })
        .finally(() => {
            setSaving(false);
        });
    };

    return (
        <AuthLayout {...props}>
            <div className={`${classes.container}`}>
                <img
                    src="/img/storevan-logo.png"
                    className="logo mb-3"
                />
                <h1 className="h3 mb-3 text-center">
                    {translate("ra.page.request_password_reset")}
                </h1>

                <Typography
                    variant="body1"
                    component="p"
                    align="center"
                >
                    {translate("ra.message.request_password_reset_description")}
                </Typography>
                <SimpleForm
                    toolbar={
                        <Toolbar className="flex-column">
                            <SaveButton
                                label="ra.action.confirm"
                                block
                                icon={<></>}
                                style={{width: "100%"}}
                            />
                            <Button
                                block
                                link
                                variant="link"
                                to="/login"
                                label={translate("ra.action.cancel")}
                            />
                        </Toolbar>
                    }
                    saving={saving}
                    save={save}
                >
                    <TextInput
                        source="email"
                        label={false}
                        placeholder={translate("general.email")}
                        validate={[required()]}
                    />
                </SimpleForm>
                <p className="mt-5 mb-3 text-muted">
                    &copy; 2014-2020 StoreVan Vlaanderen
                </p>
            </div>
        </AuthLayout>
    );
};
export default ForgotPassword;
