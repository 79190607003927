import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useChoices } from "ra-core";
import * as React from "react";
import { Form } from "react-bootstrap";

const useStyles = makeStyles(
  {
    checkbox: {
      height: 32,
    },
  },
  { name: "RaCheckboxGroupInputItem" }
);

const CheckboxGroupInputItem = (props) => {
  const {
    classes: classesOverride,
    id,
    choice,
    onChange,
    optionText,
    optionValue,
    options,
    translateChoice,
    value,
    ...rest
  } = props;
  const classes = useStyles(props);
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const choiceName = getChoiceText(choice);

  return (
    <FormControlLabel
      htmlFor={`${id}_${getChoiceValue(choice)}`}
      key={getChoiceValue(choice)}
      onChange={onChange}
      style={{ alignItems: "flex-start", marginLeft: 25 }}
      className="mb-3"
      control={
        <Form.Check
          id={`${id}_${getChoiceValue(choice)}`}
          color="primary"
          className={classes.checkbox}
          checked={
            value
              ? value.find((v) => v == getChoiceValue(choice)) !== // eslint-disable-line eqeqeq
                undefined
              : false
          }
          value={String(getChoiceValue(choice))}
          {...options}
          {...rest}
        />
      }
      label={choiceName}
    />
  );
};

export default CheckboxGroupInputItem;
