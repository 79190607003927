import TextField from "@main-components/fields/text-field";
import React, { FC } from "react";
import { FunctionField, FunctionFieldProps, ReferenceField } from "react-admin";

const ReferencePicker = ({ record }) => {
  switch (record.resource) {
    case "DEALER":
      return (
        <ReferenceField
          reference="dealers"
          source="resourceId"
          basePath="/dealers"
          record={record}
          link="show"
        >
          <TextField source="identificationId" />
        </ReferenceField>
      );

    case "REALIZATION":
      return (
        <ReferenceField
          reference="realizations"
          source="resourceId"
          basePath="/realizations"
          record={record}
          link="show"
        >
          <TextField source="title" />
        </ReferenceField>
      );
    case "ORDER":
      return (
        <ReferenceField
          reference="orders"
          source="resourceId"
          basePath="/orders"
          record={record}
          link="show"
        >
          <TextField source="orderId" />
        </ReferenceField>
      );
    case "ADMIN":
      return (
        <ReferenceField
          reference="accounts"
          source="resourceId"
          basePath="/accounts"
          record={record}
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
      );
    default:
      return <p>-</p>;
  }
};

const ResourceReferenceField: FC<Omit<
  Omit<FunctionFieldProps, "render">,
  "children"
>> = ({ record, label }) => {
  return record ? (
    <FunctionField
      label={label}
      record={record}
      render={(record) => {
        return <ReferencePicker record={record} />;
      }}
    />
  ) : null;
};

export default ResourceReferenceField;

ResourceReferenceField.defaultProps = {
  addLabel: true,
  label: "resources.logs.fields.resourceId",
};
