import Card from "@main-components/card";
import { useMediaQuery } from "@material-ui/core";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { useState } from "react";
import { useDataProvider, useTranslate } from "react-admin";

const EmptyIcon = (props) => (
  <i
    className={`fal fa-bell-exclamation mr-2`}
    style={{ fontSize: "7.5em" }}
    aria-hidden="true"
  ></i>
);
export default function StatsWidget() {
  const [state, setState] = useState<any>({});
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const translate = useTranslate();
  const { count: orderCount } = useResourceCount("orders");
  const { count: dealersCount } = useResourceCount("dealers");
  const { count: realizationsCount } = useResourceCount("realizations");

  return (
    <Card className="mb-5">
      <Card.Header>
        <i className="far fa-chart-bar mr-2" aria-hidden="true"></i>
        {translate("general.stats")}
      </Card.Header>
      <Card.Body>
        <table className="table">
          <tbody>
            <tr>
              <th>
                {translate("general.stat.count", {
                  resourceName: translate("resources.dealers.name", {
                    smart_count: 2,
                  }).toLocaleLowerCase(),
                })}
              </th>
              <td>{dealersCount}</td>
            </tr>
            <tr>
              <th>
                {translate("general.stat.count", {
                  resourceName: translate("resources.orders.name", {
                    smart_count: 2,
                  }).toLocaleLowerCase(),
                })}
              </th>
              <td>{orderCount}</td>
            </tr>
            <tr>
              <th>
                {translate("general.stat.count", {
                  resourceName: translate("resources.realizations.name", {
                    smart_count: 2,
                  }).toLocaleLowerCase(),
                })}
              </th>

              <td>{realizationsCount}</td>
            </tr>
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}
