import DateField from "@main-components/fields/date-field";
import RichTextField from "@main-components/fields/rich-text-field";
import TextField from "@main-components/fields/text-field";
import ShowContainer from "@main-components/show-container";
import SimpleShowLayout from "@main-components/show-simple-layout";
import * as React from "react";
import { useShowController } from "react-admin";

const NotificationShow = (props) => {
  const { record } = useShowController(props);

  return (
    <ShowContainer {...props}>
      <SimpleShowLayout>
        <TextField source="title" />
        <DateField source="createdAt" showTime label="general.created_at" />
        <RichTextField source="content" />
      </SimpleShowLayout>
    </ShowContainer>
  );
};

export default NotificationShow;
