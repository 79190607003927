import CreateButton from "@main-components/buttons/create-button";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import * as React from "react";

const MainActions = (props) => {
  return (
    <div>
      <CreateButton {...props} />
    </div>
  );
};

const WarehouseKeeperList = (props) => (
  <List
    {...props}
    title={props.options.label}
    sort={{ field: "createdAt", order: "desc" }}
    bulkActionButtons={false}
    exporter={false}
    // filters={<AdminFilter />}
    actions={false}
    mainActions={<MainActions />}
    component={ListWrapper}
  >
    <DataGrid rowClick="show">
      <TextField source="firstName" label="general.first_name" />
      <TextField source="lastName" label="general.last_name" />
      <TextField source="email" label="general.email" />

      <DateField source="createdAt" showTime label="general.created_at" />
    </DataGrid>
  </List>
);

export default WarehouseKeeperList;
