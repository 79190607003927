import Button from "@main-components/buttons";
import Card from "@main-components/card";
import DataGrid from "@main-components/data-grid";
import {DateField} from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {useTranslate} from "react-admin";
import {useSelector} from "react-redux";

export default function NewsWidget({isAdmin}) {
    const translate = useTranslate();
    const {count} = useResourceCount("news");
    const dashboardTotal = useSelector((state: any) => {
        return state.admin.resources["dashboard-news"]?.list?.total;
    });

    return (
        <Card className="mt-5">
            <Card.Header>
                <i
                    className="fal fa-file-alt mr-2 mr-2"
                    aria-hidden="true"
                ></i>
                {translate("resources.news.titles.recent_news")}
            </Card.Header>
            <Card.Body>
                <List
                    resource="dashboard-news"
                    basePath="/news"
                    hasCreate={true}
                    hasEdit={false}
                    hasList={true}
                    hasShow={true}
                    title=" "
                    sort={{field: "createdAt", order: "desc"}}
                    bulkActionButtons={false}
                    exporter={false}
                    component={"div"}
                    actions={false}
                    mainActions={false}
                    pagination={false}
                    perPage={5}
                    empty={
                        <Empty
                            resourceOverride="news"
                            hideActions
                            subtitle="resources.news.messages.empty_state"
                            icon={
                                <div>
                                    <img
                                        src="/img/illustrations/news.svg"
                                        className="w-100"
                                    />
                                </div>
                            }
                        />
                    }
                >
                    <DataGrid
                        rowClick="show"
                        resource="news"
                    >
                        <DateField
                            source="createdAt"
                            label="resources.news.fields.createdAt"
                            showTime
                            sortable={false}
                        />
                        <TextField
                            source="title"
                            label="resources.news.fields.title"
                        />
                    </DataGrid>
                </List>
                {isAdmin && (
                    <div
                        className={`mb-4 ${
                            dashboardTotal === 0
                                ? "d-flex align-items-center justify-content-center"
                                : ""
                        }`}
                    >
                        <Button
                            className="mr-1"
                            color="primary"
                            label={`resources.news.actions.add_post`}
                            block={false}
                        >
              <span>
                <i
                    className="fas fa-plus-circle mr-1"
                    aria-hidden="true"
                />
              </span>
                        </Button>

                        {dashboardTotal > 0 && (
                            <Button
                                label="resources.news.actions.see_overview"
                                link
                                to="/news"
                                variant="outline"
                                block={false}
                            />
                        )}
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}
