import Typography, {TypographyProps} from "@material-ui/core/Typography";
import get from "lodash/get";
import PropTypes from "prop-types";
import * as React from "react";
import {FC, memo} from "react";
import sanitizeRestProps from "../sanitizeRestProps";
import {fieldPropTypes, InjectedFieldProps, PublicFieldProps} from "../types";
import DateTimeUtils from "@misc/utils/date-time-utils";

const toLocaleStringSupportsLocales = (() => {
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    try {
        new Date().toLocaleString("i");
    } catch (error) {
        return error instanceof RangeError;
    }
    return false;
})();

export const DateField: FC<DateFieldProps> = memo<DateFieldProps>(
    ({
        className,
        emptyText,
        locales,
        options,
        record,
        showTime = false,
        source,
        ...rest
    }) => {
        if (!record) {
            return null;
        }
        const value = get(record, source);
        if (value == null) {
            return emptyText ? (
                <Typography
                    component="span"
                    variant="body2"
                    className={className}
                    {...sanitizeRestProps(rest)}
                >
                    {emptyText}
                </Typography>
            ) : null;
        }

        const date = value instanceof Date ? value : new Date(value);
        const dateString = showTime
            ? toLocaleStringSupportsLocales
                ? date.toLocaleString(locales, options)
                : date.toLocaleString()
            : toLocaleStringSupportsLocales
                ? date.toLocaleDateString(locales, options)
                : date.toLocaleDateString();

        return (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeRestProps(rest)}
            >
                {showTime ? DateTimeUtils.format(date, "YYYY-MM-DD HH:mm") : DateTimeUtils.format(date, "YYYY-MM-DD")}
            </Typography>
        );
    }
);

DateField.defaultProps = {
    addLabel: true,
};

DateField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    ...fieldPropTypes,
    locales: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    options: PropTypes.object,
    showTime: PropTypes.bool,
};

export interface DateFieldProps
    extends PublicFieldProps,
        InjectedFieldProps,
        TypographyProps {
    locales?: string | string[];
    options?: object;
    showTime?: boolean;
}

export default DateField;
