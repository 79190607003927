import React, {FC} from "react";
import {ChoicesInputProps, useTranslate} from "react-admin";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {orderStatus} from "@main/orders/shared/status-field";
import SelectInput from "@main-components/inputs/select-input";

const OrderStatusInput: FC<Omit<Omit<ChoicesInputProps, "source" | "choices">, "children">> = ({
    record,
    label,
    onChange,
    ...rest
}) => {

    const translate = useTranslate()
    const choices = orderStatusOptions[record.status] ?? []


    /*  if (choices.length === 0) return <div/>*/


    return (
        <>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip
                    id="status"
                    placement={"top"}
                >
                    {record.status === "SCHEDULED" &&
                        <>
                            {translate("resources.orders.titles.complete_warning")}
                        </>
                    }

                    {record.status === "PROCESSING" &&
                        <>
                            {translate("resources.orders.titles.schedule_warning")}. {translate("resources.orders.titles.cancel_warning")}
                        </>
                    }
                </Tooltip>}
                show={record.status === "SCHEDULED" || record.status === "PROCESSING" ? undefined : false}
            >

                <SelectInput
                    source="status"
                    choices={choices.length === 0 ? orderStatus : choices}
                    translateChoice={true}
                    disabled={choices.length === 0}
                    onChange={(c) => {
                        onChange && onChange(c.target.value);
                    }}
                />
            </OverlayTrigger>
        </>

    )
};

const orderStatusOptions = {
    "RECEIVED": [
        {
            name: "resources.orders.status.being_processed",
            id: "PROCESSING",
        },
        {
            name: "resources.orders.status.needs_more_info",
            id: "NEED_MORE_INFO",
        },
        {
            name: "resources.orders.status.canceled",
            id: "CANCELED",
        }
    ],
    "IN_BACK_ORDER": [
        {
            name: "resources.orders.status.being_processed",
            id: "PROCESSING",
        },
        {
            name: "resources.orders.status.needs_more_info",
            id: "NEED_MORE_INFO",
        },
        {
            name: "resources.orders.status.canceled",
            id: "CANCELED",
        }
    ],
    "NEED_MORE_INFO": [
        {
            name: "resources.orders.status.being_processed",
            id: "PROCESSING",
        },
        {
            name: "resources.orders.status.in_backorder",
            id: "IN_BACK_ORDER",
        },
        {
            name: "resources.orders.status.canceled",
            id: "CANCELED",
        }
    ],
    "PROCESSING": [],
    "SCHEDULED": []
}
export default OrderStatusInput;

OrderStatusInput.defaultProps = {
    label: "Status",
};
