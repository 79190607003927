import Labeled from "@main-components/inputs/Labeled";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Record } from "ra-core";
import * as React from "react";
import { Children, cloneElement, isValidElement, ReactNode } from "react";
import CardContentInner from "../layout/CardContentInner";

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  translate,
  ...rest
}: any) => rest;

const SimpleShowLayout = ({
  basePath,
  className,
  children,
  record,
  resource,
  version,
  ...rest
}: SimpleShowLayoutProps) => (
  <CardContentInner
    className={className}
    key={version}
    {...sanitizeRestProps(rest)}
  >
    {Children.map(children, (field) =>
      field && isValidElement<any>(field) ? (
        <div
          key={field.props.source}
          className={classnames(
            `ra-field ra-field-${field.props.source}`,
            field.props.className
          )}
        >
          {field.props.addLabel ? (
            <Labeled
              record={record}
              resource={resource}
              basePath={basePath}
              label={field.props.label}
              source={field.props.source}
              disabled={false}
            >
              {field}
            </Labeled>
          ) : typeof field.type === "string" ? (
            field
          ) : (
            cloneElement(field, {
              record,
              resource,
              basePath,
            })
          )}
        </div>
      ) : null
    )}
  </CardContentInner>
);

export interface SimpleShowLayoutProps {
  basePath?: string;
  className?: string;
  children: ReactNode;
  record?: Record;
  resource?: string;
  version?: number;
}

SimpleShowLayout.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  record: PropTypes.object,
  resource: PropTypes.string,
  version: PropTypes.number,
};

export default SimpleShowLayout;
