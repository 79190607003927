import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { required, useNotify, useRefresh, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";

export const transformDownloadData = (data, categoryId) => {
  if (data.file.rawFile) {
    data.file = data.file.rawFile;
  }

  data.categoryId = categoryId;
  return data;
};

const DownloadCreateFrom = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <CreateFormContainer
      {...props}
      component={"div"}
      transform={(values) => transformDownloadData(values, props.categoryId)}
      onSuccess={(newRecord) => {
        props.handleClose();
        refresh();
        /* @ts-ignore */
        notify("resources.downloads.notifications.created", "success");
      }}
    >
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput multiline source="description" />
        {/*  <ReferenceInput
          source="categoryId"
          reference="downloads"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}

        <SimpleFileInput
          source="file"
          label="general.file"
          validate={[required()]}
          InputProps={{
            placeholder: "general.select_file",
          }}
        />
      </SimpleForm>
    </CreateFormContainer>
  );
};

const DownloadCreateModal = ({ resource, basePath, categoryId }) => {
  const { createActive, setCreateActive } = useOverviewContext();

  const handleClose = () => {
    setCreateActive(false);
  };
  const translate = useTranslate();

  return (
    <Modal show={createActive} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.downloads.titles.create_download")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <DownloadCreateFrom
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
          categoryId={categoryId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DownloadCreateModal;
