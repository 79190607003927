import React from "react";
import DealerList from "./list";
import DealerShow from "./show";

const resource = {
  list: DealerList,
  /*  create: DealerCreate,
  edit: DealerEdit, */
  show: DealerShow,
  icon: () => <i className="far fa-users fa-fw"></i>,
};

export default resource;
