import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DefaultIcon from "@material-ui/icons/ViewList";
import {find} from "lodash";
import * as React from "react";
import {getResources, setSidebarVisibility, usePermissions, useTranslate,} from "react-admin";
import {useDispatch, useSelector} from "react-redux";
import DashboardMenuItem from "./components/dashboard-menu-item";
import MenuItemLink from "./components/menu-item-link";
import SubMenu from "./submenu";

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {paddingTop: theme.spacing(4)},
}));

const useMenuList = () => {
    const {permissions} = usePermissions();
    const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";
    const menu = [
        ...(isAdmin
            ? [
                {
                    label: "users",
                    name: "users",
                    icon: <i className="far fa-users fa-fw"></i>,
                    items: [
                        ...(permissions === "SUPERADMIN"
                            ? [
                                {
                                    label: "Admins",
                                    resource: "accounts",
                                },
                            ]
                            : []),
                        {
                            label: "Warehouse Keepers",
                            resource: "warehouse-keepers",
                        },
                        {
                            label: "Dealers",
                            resource: "dealers",
                        },
                    ],
                },
            ]
            : []),
        {
            label: "Orders",
            resource: "orders",
        },
        {
            label: "Products",
            resource: "products",
        },

        ...(isAdmin
            ? [
                {
                    label: "Webshop",
                    resource: "webshop-products",
                    customLabel: "resources.webshop-products.menu"
                },
                {
                    label: "downloads",
                    resource: "downloads",
                },
            ]
            : []),

        {
            label: "News",
            resource: "news",
        },

        ...(isAdmin
            ? [
                {
                    label: "Realizations",
                    resource: "realizations",
                },

                {
                    label: "Logs",
                    resource: "logs",
                },
            ]
            : []),
    ];
    return {menu: menu};
};

const SideMenu: React.FC<Props> = ({onMenuClick, dense, logout}) => {
    const [state, setState] = React.useState({
        users: false,
    });
    const dispatch = useDispatch();
    const {permissions} = usePermissions();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

    const resources = useSelector(getResources);
    const classes = useStyles();

    const {menu: MenuTree} = useMenuList();

    const hideSidebar = () => dispatch(setSidebarVisibility(false));

    if (resources.length === 0) {
        return null;
    }

    const handleClick = () => {
        if (isXSmall) {
            hideSidebar();
        }
        onMenuClick && onMenuClick();
    };

    const handleToggle = (menu: string) => {
        setState((state) => ({...state, [menu]: !state[menu]}));
    };

    const isAdmin = permissions === "SUPERADMIN" || permissions === "ADMIN";

    return (
        <nav
            id="sidebarMenu"
            className={`col-md-3 col-lg-2 d-md-block sidebar 
         ${open ? "" : "hide"}`}
        >
            <div className="position-sticky">
                <ul className="nav flex-column primary">
                    <DashboardMenuItem
                        onClick={handleClick}
                        sidebarIsOpen={open}
                    />
                </ul>
                <ul className="nav flex-column">
                    {MenuTree.map((item) => {
                        if (item.items) {
                            return (
                                <SubMenu
                                    handleToggle={() => handleToggle(item.name)}
                                    isOpen={state[item.name]}
                                    sidebarIsOpen={open}
                                    name={`general.${item.label}`}
                                    icon={item.icon}
                                    dense={dense}
                                >
                                    {item.items.map((subitem) => {
                                        const resource = find(resources, {
                                            name: subitem.resource,
                                        });

                                        const label = (() => {

                                            return translate(
                                                `resources.${resource.name}.name`,
                                                {
                                                    smart_count: 2,
                                                }
                                            )
                                        })()
                                        return (
                                            <MenuItemLink
                                                key={resource.name}
                                                to={`/${resource.name}`}
                                                primaryText={label}
                                                /*  leftIcon={
                                                  resource.icon ? <resource.icon /> : <DefaultIcon />
                                                } */
                                                onClick={onMenuClick}
                                                sidebarIsOpen={open}
                                                dense={dense}
                                            />
                                        );
                                    })}
                                </SubMenu>
                            );
                        }

                        const resource = find(resources, {name: item.resource});

                        const label = (() => {

                            if (item.customLabel) {
                                return translate(item.customLabel)
                            }

                            return translate(
                                `resources.${resource.name}.name`,
                                {
                                    smart_count: 2,
                                }
                            )
                        })()

                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={label}
                                leftIcon={resource.icon ? <resource.icon/> : <DefaultIcon/>}
                                onClick={handleClick}
                                sidebarIsOpen={open}
                            />
                        );
                    })}

                    {isAdmin && (
                        <MenuItemLink
                            key="companyInfo"
                            to="/company-info"
                            primaryText={translate("general.company_info")}
                            leftIcon={<i className="far fa-briefcase fa-fw"></i>}
                            sidebarIsOpen={open}
                            onClick={handleClick}
                        />
                    )}
                </ul>

                <ul className="nav flex-column">
                    <MenuItemLink
                        key="profile"
                        to="/profile"
                        primaryText={translate("general.profile")}
                        leftIcon={<i className="far fa-user fa-fw"></i>}
                        sidebarIsOpen={open}
                        onClick={handleClick}
                    />

                    <MenuItemLink
                        key="settings"
                        to="/settings"
                        primaryText={translate("general.configuration")}
                        leftIcon={<i className="far fa-cog fa-fw"></i>}
                        sidebarIsOpen={open}
                        onClick={handleClick}
                    />
                </ul>
            </div>
            {/* {isXSmall && logout} */}
        </nav>
    );
};

export default SideMenu;
