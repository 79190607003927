import CreateButton from "@main-components/buttons/create-button";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {useOverviewState} from "@main-components/list/utils/use-overview-context";
import {Tooltip} from "@material-ui/core";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import {Filter, FunctionField, TopToolbar, useTranslate} from "react-admin";
import OrderDealerModal from "../create";

const DealerFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput
                source="identificationId"
                alwaysOn
                label={false}
                placeholder={translate("general.search")}
                InputProps={{
                    startAdornment: (
                        <span
                            className="input-group-text h-100"
                            id="search-addon"
                        >
              <i
                  className="fal fa-search"
                  aria-hidden="true"
              ></i>
            </span>
                    ),
                }}
            />
        </Filter>
    );
};

const DealerListActions = (props) => {
    return (
        <TopToolbar className="m-0 p-0 pt-1">
            <DealerFilter context="button"/>
        </TopToolbar>
    );
};

const MainActions = (props) => {
    const {setCreateActive} = useOverviewContext();
    return (
        <div>
            <CreateButton {...props} onClick={() => setCreateActive(true)}/>
        </div>
    );
};

const DealerListView = (props) => {
    const {count} = useResourceCount("dealers");
    const translate = useTranslate();
    const {setCreateActive} = useOverviewContext();

    const subHeader =
        count > 0
            ? `${count} ${translate("resources.dealers.name", {
                smart_count: count === 1 ? 1 : 2,
            })}`
            : "";
    return (
        <>
            <List
                {...props}
                title={props.options.label}
                listSubHeader={subHeader}
                sort={{field: "createdAt", order: "desc"}}
                bulkActionButtons={false}
                exporter={false}
                filters={<DealerFilter/>}
                actions={<DealerListActions/>}
                mainActions={count > 0 && <MainActions/>}
                component={ListWrapper}
                hasCreate={true}
                empty={
                    <Empty
                        resourceOverride="dealers"
                        actions={
                            <CreateButton
                                {...props}
                                onClick={() => {
                                    setCreateActive(true);
                                }}
                            />
                        }
                    />
                }
            >
                <DataGrid rowClick="show">
                    <TextField source="identificationId"/>
                    <TextField
                        source="exactId"
                        label={"Exact ID"}
                    />
                    <TextField source="companyName"/>
                    <TextField source="brandName"/>
                    <TextField source="vatNumber"/>
                    <DateField
                        source="createdAt"
                        showTime
                        label="general.created_at"
                    />

                    <FunctionField
                        render={(record) => {
                            if (!record || (record && !record.passwordResetRequestedAt)) {
                                return;
                            }

                            return (
                                <Tooltip
                                    title={translate(
                                        "resources.dealers.messages.dealer_requested_password_change"
                                    )}
                                    placement="top"
                                    id={`${record.id}-ts`}
                                >
                                    <i
                                        className="fa fa-info-circle text-warning"
                                        style={{fontSize: 20}}
                                    ></i>
                                </Tooltip>
                            );
                        }}
                    />
                </DataGrid>
            </List>
            <OrderDealerModal
                resource={props.resource}
                basePath={props.basePath}
            />
        </>
    );
};
const DealerList = (props) => {
    return (
        <OverviewContext.Provider value={{...useOverviewState()}}>
            <DealerListView {...props} />
        </OverviewContext.Provider>
    );
};

export default DealerList;
