import React from "react";
import DownloadCategoryShow from "./show";

const resource = {
  /* create: DownloadCreate,
  edit: DownloadEdit, */
  show: DownloadCategoryShow,
  icon: () => <i className="far fa-cloud-download fa-fw"></i>,
};

export default resource;
