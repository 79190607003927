import ShowCompany from "@main/company/show";
import ForgotPassword from "@main/forgot-password";
import ShowProfile from "@main/profile/show";
import ResetPassword from "@main/reset-password";
import Settings from "@main/settings";
import SyncProducts from "@main/sync-products";
import * as React from "react";
import { Route } from "react-router-dom";

export default [
  <Route exact path="/settings" render={() => <Settings />} />,
  <Route path="/resync-products" component={SyncProducts} />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route
    exact
    path="/reset-password/:token"
    component={ResetPassword}
    noLayout
  />,
  <Route exact path="/profile" component={ShowProfile} />,
  <Route exact path="/company-info" component={ShowCompany} />,
];
