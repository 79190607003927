import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Record, useTranslate, ValidationError } from "ra-core";
import * as React from "react";
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  useRef,
} from "react";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import FormInput from "../form-input";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        //transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        //transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        // transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        //transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "1em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaSimpleFormIterator" }
);

const DefaultAddButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Button size="small" {...props}>
      <AddIcon className={classes.leftIcon} />
      {translate("ra.action.add")}
    </Button>
  );
};

const DefaultRemoveButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Button size="small" {...props}>
      <CloseIcon className={classes.leftIcon} />
      {translate("ra.action.remove")}
    </Button>
  );
};

const SimpleFormIterator: FC<SimpleFormIteratorProps> = (props) => {
  const {
    addButton = <DefaultAddButton />,
    removeButton = <DefaultRemoveButton />,
    basePath,
    children,
    className,
    fields,
    /* @ts-ignore */
    meta: { error, submitFailed },
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    variant,
    margin,
    TransitionProps,
    defaultValue,
  } = props;
  const classes = useStyles(props);

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  );

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  );

  const removeField = (index) => () => {
    ids.current.splice(index, 1);
    /* @ts-ignore */
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === "boolean") {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  const addField = () => {
    ids.current.push(nextId.current++);
    /* @ts-ignore */
    fields.push(undefined);
  };

  // add field and call the onClick event of the button passed as addButton prop
  const handleAddButtonClick = (originalOnClickHandler) => (event) => {
    addField();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  // remove field and call the onClick event of the button passed as removeButton prop
  const handleRemoveButtonClick = (originalOnClickHandler, index) => (
    event
  ) => {
    removeField(index)();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  const records = get(record, source);
  return fields ? (
    <ul className={classNames(classes.root, className)}>
      {submitFailed && typeof error !== "object" && error && (
        <FormHelperText error>
          <ValidationError error={error as string} />
        </FormHelperText>
      )}
      <TransitionGroup component={null}>
        {fields.map((member, index) => (
          <CSSTransition
            key={ids.current[index]}
            timeout={300}
            classNames="fade"
            {...TransitionProps}
          >
            <li className={classes.line}>
              <Typography variant="body1" className={classes.index}>
                {index + 1}
              </Typography>
              <section className={classes.form}>
                {/* @ts-ignore */}
                {Children.map(children, (input: ReactElement, index2) =>
                  isValidElement<any>(input) ? (
                    <FormInput
                      basePath={input.props.basePath || basePath}
                      input={cloneElement(input, {
                        source: input.props.source
                          ? `${member}.${input.props.source}`
                          : member,
                        index: input.props.source ? undefined : index2,
                        label:
                          typeof input.props.label === "undefined"
                            ? input.props.source
                              ? `resources.${resource}.fields.${input.props.source}`
                              : undefined
                            : input.props.label,
                      })}
                      /* @ts-ignore */
                      record={(records && records[index]) || {}}
                      /* @ts-ignore */
                      resource={resource}
                      variant={variant}
                      margin={margin}
                    />
                  ) : null
                )}
              </section>
              {!disableRemoveField(
                (records && records[index]) || {},
                disableRemove
              ) && (
                <span className={classes.action}>
                  {cloneElement(removeButton, {
                    onClick: handleRemoveButtonClick(
                      removeButton.props.onClick,
                      index
                    ),
                    className: classNames(
                      "button-remove",
                      `button-remove-${source}-${index}`
                    ),
                  })}
                </span>
              )}
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {!disableAdd && (
        <li className={classes.line}>
          <span className={classes.action}>
            {cloneElement(addButton, {
              onClick: handleAddButtonClick(addButton.props.onClick),
              className: classNames("button-add", `button-add-${source}`),
            })}
          </span>
        </li>
      )}
    </ul>
  ) : null;
};

SimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

SimpleFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  addButton: PropTypes.element,
  removeButton: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  // @ts-ignore
  fields: PropTypes.object,
  meta: PropTypes.object,
  // @ts-ignore
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
};

type DisableRemoveFunction = (record: Record) => boolean;

export interface SimpleFormIteratorProps
  extends Partial<Omit<FieldArrayRenderProps<any, HTMLElement>, "meta">> {
  addButton?: ReactElement;
  basePath?: string;
  className?: string;
  defaultValue?: any;
  disableAdd?: boolean;
  disableRemove?: boolean | DisableRemoveFunction;
  margin?: "none" | "normal" | "dense";
  meta?: {
    // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
    error?: any;
    submitFailed?: boolean;
  };
  record?: Record;
  removeButton?: ReactElement;
  resource?: string;
  source?: string;
  TransitionProps?: any;
  variant?: "standard" | "outlined" | "filled";
}

export default SimpleFormIterator;
