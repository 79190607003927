import DefaultNotification from "@main-components/notification";
import {makeStyles, Theme, ThemeProvider} from "@material-ui/core";
import theme from "@theme";
import React, {createElement} from "react";

const useStyles = makeStyles(
    (theme: Theme) => ({
        bgImage: {
            backgroundImage: "url(/img/example-bg.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        },
        main: {
            minHeight: "100vh",
            height: "100vh",
            backgroundColor: "#fff",
        },
        form: {
            minWidth: 300,
            marginTop: "6em",
        },
        avatar: {
            padding: theme.spacing(2),
        },
        left: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        right: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        icon: {
            width: "100%",
        },
    }),
    {name: "AuthLayout"}
);

const AuthLayout: React.FunctionComponent<any> = (props) => {
    const classes = useStyles(props);

    return (
        <ThemeProvider theme={theme}>
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div
                        className={`d-none d-lg-block col-lg-6 h-100 p-0 bg-image ${classes.bgImage}`}
                    >
                        <div className="overlay w-100 h-100"></div>
                    </div>
                    <div className="col-xs-12 col-lg-6 h-100  d-flex align-items-center">
                        {props.children}
                    </div>
                </div>
                {createElement(props.notification)}
            </div>
        </ThemeProvider>
    );
};

AuthLayout.defaultProps = {
    theme: theme,
    notification: DefaultNotification,
};

export default AuthLayout;
