import ImageField from "@main-components/fields/image-field";
import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import ImageInput from "@main-components/inputs/image-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { required, useNotify, useRedirect, useTranslate } from "react-admin";
import { Modal } from "react-bootstrap";

export const transformDealerData = (data) => {
  if (data.logoUrl && data.logoUrl.rawFile) {
    data.logoUrl = data.logoUrl.rawFile;
  }

  if (
    data.mainContact.profilePictureUrl &&
    data.mainContact.profilePictureUrl.rawFile
  ) {
    data.mainContact.profilePictureUrl =
      data.mainContact.profilePictureUrl.rawFile;
  }

  data.alternativeContacts = data.alternativeContacts
    ? data.alternativeContacts.map((contact) => {
        if (contact.profilePictureUrl && contact.profilePictureUrl.rawFile) {
          contact.profilePictureUrl = contact.profilePictureUrl.rawFile;
        }
        return contact;
      })
    : [];

  return data;
};

const DealerCreateForm = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <CreateFormContainer
      title=" "
      {...props}
      transform={transformDealerData}
      component={"div"}
      onSuccess={(newDealer) => {
        props.handleClose();
        redirect("show", "/dealers", newDealer.data.id);
        /* @ts-ignore */
        notify("resources.dealers.notifications.dealer_created", "success");
      }}
    >
      <SimpleForm redirect="list">
        <ImageInput source="logoUrl" accept="image/jpeg,image/png">
          <ImageField source="src" />
        </ImageInput>
        <TextInput
          source="brandName"
          validate={[required()]}
          helperText="resources.dealers.messages.brand_name"
        />
        <TextInput
          source="vatNumber"
          //validate={[required()]}
        />

        <TextInput
          source="businessAddress"
          //validate={[required()]}
          helperText="resources.dealers.messages.business_address"
        />

        <TextInput
          source="companyName"
          //validate={[required()]}
          helperText="resources.dealers.messages.company_name"
        />

        <TextInput
          source="headOfficeAddress"
          //validate={[required()]}
          helperText="resources.dealers.messages.head_office_address"
        />

        <ImageInput
          source="mainContact.profilePictureUrl"
          label="general.profile_picture"
          accept="image/jpeg,image/png"
          name="mainContact.profilePictureUrl"
        >
          <ImageField source="src" />
        </ImageInput>
        <TextInput
          source="mainContact.firstName"
          label="general.first_name"
          name="mainContact.firstName"
          validate={[required()]}
          helperText="resources.dealers.messages.main_contact_first_name"
        />
        <TextInput
          source="mainContact.lastName"
          label="general.last_name"
          name="mainContact.lastName"
          helperText="resources.dealers.messages.main_contact_last_name"
          validate={[required()]}
        />
        <TextInput
          source="mainContact.email"
          label="general.email"
          validate={[required()]}
          helperText="resources.dealers.messages.main_contact_email"
          name="mainContact.email"
        />
        <TextInput
          source="mainContact.companyRole"
          label="resources.dealers.fields.companyRole"
          helperText="resources.dealers.messages.main_contact_role"
          name="mainContact.companyRole"
          // validate={[required()]}
        />
        <TextInput
          source="mainContact.phoneNumber"
          label="general.phone"
          helperText="resources.dealers.messages.main_contact_phone"
          name="mainContact.phoneNumber"
          //validate={[required()]}
        />
      </SimpleForm>
    </CreateFormContainer>
  );
};
const OrderDealerModal = ({ resource, basePath }) => {
  const { createActive, setCreateActive } = useOverviewContext();

  const handleClose = () => {
    setCreateActive(false);
  };
  const translate = useTranslate();

  return (
    <Modal show={createActive} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.dealers.titles.create_dealer")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <DealerCreateForm
          resource={resource}
          basePath={basePath}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default OrderDealerModal;
